import React from 'react';

import StandardLayout from 'sow/components/templates/StandardLayout';
import TodoListFiltersContainer from 'sow/containers/TodoListFiltersContainer';
import TodoListTableContainer from 'sow/containers/TodoListTableContainer';

const TodoListClientPage = () => (
  <StandardLayout title="To-do List">
    <TodoListFiltersContainer />
    <TodoListTableContainer />
  </StandardLayout>
);

export default TodoListClientPage;
