import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { push as routerPush } from 'connected-react-router';

import { priorityTypeRoute } from 'sow/routes';
import * as priorityTypeActions from 'sow/actions/pure/priorityType';

import PriorityTypeEdit from 'sow/components/organisms/PriorityTypeEdit';

const mapStateToProps = (state, props) => ({
  isNew: R.propEq('id', 'new')(props.match.params),
  priorityTypeId: R.path(['match', 'params', 'id'], props),
});

const mapDispatchToProps = {
  fetchPriorityType: priorityTypeActions.fetchById,
  addPriorityType: priorityTypeActions.addPriorityType,
  updatePriorityType: priorityTypeActions.updatePriorityType,
  deletePriorityType: priorityTypeActions.deletePriorityType,
  redirect: routerPush,
};

const initialState = {
  isLoading: true,
  initialValues: { sort_order: 1, enabled: true, color: '#000000' },
};

class PriorityTypeEditContainer extends Component {
  constructor(props) {
    super(props);

    this.state = R.clone(initialState);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.props.isNew) {
      this.props.fetchPriorityType(
        this.props.priorityTypeId,
        this.setInitialValues.bind(this),
      );
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState({ id }) {
    if (this.props.isNew) {
      this.props.history.push(`/admin/system-admin/object/priority-type/${id}`);
    }
  }

  redirectToList() {
    this.props.redirect(priorityTypeRoute());
  }

  setInitialValues(data) {
    if (this._isMounted) {
      this.setState({
        isLoading: false,
        initialValues: {
          sort_order: data.sort_order,
          name: data.name,
          enabled: data.enabled,
          color: data.color,
        },
      });
    }
  }

  onSave(values, formikBag) {
    const { isNew, priorityTypeId, addPriorityType, updatePriorityType } = this.props;
    if (isNew) {
      addPriorityType(values, this.updateState.bind(this));
    } else {
      updatePriorityType(priorityTypeId, values);
    }
  }

  onDelete() {
    const { priorityTypeId, deletePriorityType } = this.props;
    deletePriorityType(priorityTypeId, this.redirectToList.bind(this));
  }

  render() {
    return (
      <PriorityTypeEdit
        onSave={this.onSave.bind(this)}
        onDelete={this.onDelete.bind(this)}
        {...this.props}
        {...this.state}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PriorityTypeEditContainer);
