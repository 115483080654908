import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as adminRequestActions from 'sow/actions/adminRequest';
import PlanAppChange from 'sow/components/organisms/PlanAppChange';
import { PlanAppChangeLoader } from 'sow/store/containers';
import * as sowTypes from 'sow/types';
import * as currentUser from 'sow/selectors/currentUser';
import * as currentOrg from 'sow/selectors/currentOrg';
import { fromPlanApp } from 'sow/store/selectors';

const mapStateToProps = (state, props) => ({
  orgId: currentOrg.orgId(state, props),
  showAcaUI:
    currentUser.showAcaUI(state, props) && !currentUser.isInspector(state, props),
  changeRequestIsOpen: fromPlanApp.changeRequestIsOpen(state, props),
  change: fromPlanApp.change(state, props),
  hasUnreadNotifications: fromPlanApp.questionHasCommentNotifications(state, props),
});

const mapDispatchToProps = dispatch => ({
  refreshAdminRequests: (orgId, planAppId, landId) =>
    adminRequestActions.refreshAdminRequests(orgId, planAppId, landId)(dispatch),
});

const createEventHandler = fn => event => {
  event.preventDefault;
  fn();
};

const PlanAppChangeContainer = ({
  orgId,
  change,
  changeId,
  showAcaUI,
  showChangeUI,
  renderAnswer,
  renderChange,
  changeRequestIsOpen,
  noCollapse,
  changeUIDisabled,
  hasUnreadNotifications,
  refreshAdminRequests,
}) => {
  return (
    <PlanAppChangeLoader changeId={changeId} action="accept">
      {({ updateResource: acceptChange, status: acceptStatus }) => {
        if (acceptStatus.loading && change.type == 'matrix_row')
          refreshAdminRequests(orgId, change.applicationId, change.landId);

        return (
          <PlanAppChangeLoader changeId={changeId} action="reject">
            {({ updateResource: rejectChange, status: rejectStatus }) => (
              <PlanAppChange
                change={change}
                renderAnswer={renderAnswer}
                renderChange={renderChange}
                onAccept={createEventHandler(acceptChange)}
                onReject={createEventHandler(rejectChange)}
                acceptStatus={acceptStatus}
                rejectStatus={rejectStatus}
                showAcaUI={showAcaUI}
                showChangeUI={showChangeUI}
                changeRequestIsOpen={changeRequestIsOpen}
                noCollapse={noCollapse}
                changeUIDisabled={changeUIDisabled}
                hasUnreadNotifications={hasUnreadNotifications}
              />
            )}
          </PlanAppChangeLoader>
        );
      }}
    </PlanAppChangeLoader>
  );
};

PlanAppChangeContainer.propTypes = {
  // Passed props
  changeId: sowTypes.planAppChangeIdType,
  renderAnswer: PropTypes.func,
  renderChange: PropTypes.func.isRequired,
  noCollapse: PropTypes.bool,
  changeUIDisabled: PropTypes.bool,

  // Connected props
  change: sowTypes.planAppChangeType,
  showAcaUI: PropTypes.bool,
  /** Manually hide the change UI - used for 'descriptive_text' field type */
  showChangeUI: PropTypes.bool,
  hasUnreadNotifications: PropTypes.bool,

  changeRequestIsOpen: PropTypes.bool,
};

PlanAppChangeContainer.defaultProps = {
  showChangeUI: true,
  renderAnswer: null,
  noCollapse: false,
  changeUIDisabled: false,
  hasUnreadNotifications: false,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PlanAppChangeContainer);
