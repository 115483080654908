import React from 'react';

import { todoListRoute } from 'sow/routes';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import List from 'sow/components/atoms/List';
import IconWithText from 'sow/components/molecules/IconWithText';
import SidebarLink from 'sow/components/molecules/SidebarLink';

const SidebarTodo = ({ navClasses }) => (
  <List className={navClasses}>
    <SidebarLink to={todoListRoute()} exact>
      <IconWithText icon={<FontAwesome icon="list" />} text="To-do List" />
    </SidebarLink>
  </List>
);

export default SidebarTodo;
