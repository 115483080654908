import React, { Fragment } from 'react';
import moment from 'moment';

import { monthDayYearDateFormat } from 'sow/utils/dateTime';
import { orgRoute } from 'sow/routes';

import PlanAppProgressCircle from 'sow/components/organisms/PlanAppProgressCircle';
import Block from 'sow/components/atoms/Block';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Spacer from 'sow/components/atoms/Spacer';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Heading from 'sow/components/atoms/Heading';
import Link from 'sow/components/atoms/Link';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';

const UnannouncedInspectionReport = ({
  completedCount,
  pendingCount,
  pendingReviewCount,
  finalizedCount,
  inspectionYearData,
  completedInspections,
  pendingInspections,
  pendingReview,
  finalizedInspections,
}) => (
  <Widget className="unannounced-inspection-report">
    <WidgetHeading>Inspection Year: {inspectionYearData.year}</WidgetHeading>
    <WidgetBody>
      <Row className="info-row">
        <Column xs={12} sm={6} md={4} lg={2}>
          <PlanAppProgressCircle
            percentageComplete={
              completedCount > inspectionYearData.required_quantity
                ? 100
                : (completedCount / inspectionYearData.required_quantity) * 100
            }
            innerTextContent={
              <Fragment>
                <Block className="percentage">
                  {Math.round(
                    (completedCount / inspectionYearData.required_quantity) * 100,
                  )}
                  %
                </Block>
                <Block className="percentage-label">Completed</Block>
              </Fragment>
            }
          />
        </Column>
        <Column xs={12} sm={6} md={4} lg={2} className="info-card">
          <Spacer vertical={50} />
          <Block className="data-display">
            {completedCount} of {inspectionYearData.required_quantity}
          </Block>
          <Spacer vertical={10} />
          <Block className="data-text">Completed</Block>
        </Column>
        <Column xs={12} sm={6} md={4} lg={2} className="info-card">
          <Spacer vertical={50} />
          <Block className="data-display">{pendingCount}</Block>
          <Spacer vertical={10} />
          <Block className="data-text">Pending Inspection</Block>
        </Column>
        <Column xs={12} sm={6} md={4} lg={2} className="info-card">
          <Spacer vertical={50} />
          <Block className="data-display">{pendingReviewCount}</Block>
          <Spacer vertical={10} />
          <Block className="data-text">Pending Review</Block>
        </Column>
        <Column xs={12} sm={6} md={4} lg={2} className="info-card">
          <Spacer vertical={50} />
          <Block className="data-display">{finalizedCount}</Block>
          <Spacer vertical={10} />
          <Block className="data-text">Finalized</Block>
        </Column>
        <Column xs={12} sm={6} md={4} lg={2} className="info-card">
          <Spacer vertical={50} />
          <Block className="data-display">
            {inspectionYearData.average_inspection_age
              ? inspectionYearData.average_inspection_age === 1
                ? `${inspectionYearData.average_inspection_age} day`
                : `${inspectionYearData.average_inspection_age} days`
              : `0 days`}
          </Block>
          <Spacer vertical={10} />
          <Block className="data-text">Average Inspection Age</Block>
        </Column>
      </Row>
      <Spacer vertical={50} />
      <Heading>Completed Inspections</Heading>
      <Table
        className="unannounced-table"
        striped
        head={
          <TableRow>
            <TableCell heading>Inspection ID</TableCell>
            <TableCell heading>Organization</TableCell>
            <TableCell heading>Inspection Type</TableCell>
            <TableCell heading>Inspector</TableCell>
            <TableCell heading>Inspection Date</TableCell>
            <TableCell heading>Inspection Status</TableCell>
          </TableRow>
        }
      >
        {completedCount > 0 ? (
          completedInspections.map(inspection => (
            <TableRow key={inspection.id}>
              <TableCell>{inspection.id}</TableCell>
              <TableCell>
                <Link to={orgRoute(inspection.org.id)}>{inspection.org.name}</Link>
              </TableCell>
              <TableCell>{inspection.inspection_type}</TableCell>
              <TableCell>{inspection.inspector}</TableCell>
              <TableCell>
                {inspection.inspection_date
                  ? monthDayYearDateFormat(inspection.inspection_date)
                  : 'Not set'}
              </TableCell>
              <TableCell>{inspection.inspection_state}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell textCenter colSpan={6}>
              No completed inspections.
            </TableCell>
          </TableRow>
        )}
      </Table>
      <Spacer vertical={50} />
      <Heading>Pending Inspections</Heading>
      <Table
        className="unannounced-table"
        striped
        head={
          <TableRow>
            <TableCell heading>Inspection ID</TableCell>
            <TableCell heading>Organization</TableCell>
            <TableCell heading>Inspection Type</TableCell>
            <TableCell heading>Inspector</TableCell>
            <TableCell heading>Inspection Date</TableCell>
            <TableCell heading>Released To Inspector</TableCell>
            <TableCell heading>Inspection Age</TableCell>
            <TableCell heading>Inspection Status</TableCell>
          </TableRow>
        }
      >
        {pendingCount > 0 ? (
          pendingInspections.map(inspection => (
            <TableRow key={inspection.id}>
              <TableCell>{inspection.id}</TableCell>
              <TableCell>
                <Link to={orgRoute(inspection.org.id)}>{inspection.org.name}</Link>
              </TableCell>
              <TableCell>{inspection.inspection_type}</TableCell>
              <TableCell>{inspection.inspector}</TableCell>
              <TableCell>
                {inspection.inspection_date
                  ? monthDayYearDateFormat(inspection.inspection_date)
                  : 'Not set'}
              </TableCell>
              <TableCell>
                {inspection.released_on
                  ? moment
                      .utc(inspection.released_on)
                      .local()
                      .format('MM/DD/YYYY')
                  : 'Not released'}
              </TableCell>
              <TableCell>
                {inspection.inspection_age > 0
                  ? `${inspection.inspection_age} days old`
                  : inspection.inspection_age === 0
                  ? '< 1 day old'
                  : 'Not released'}
              </TableCell>
              <TableCell>{inspection.inspection_state}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell textCenter colSpan={8}>
              No pending inspections.
            </TableCell>
          </TableRow>
        )}
      </Table>
      <Spacer vertical={50} />
      <Heading>Inspections Pending Review</Heading>
      <Table
        className="unannounced-table"
        striped
        head={
          <TableRow>
            <TableCell heading>Inspection ID</TableCell>
            <TableCell heading>Organization</TableCell>
            <TableCell heading>Inspection Type</TableCell>
            <TableCell heading>Inspection Date</TableCell>
            <TableCell heading>Inspection Age</TableCell>
            <TableCell heading>Inspection Status</TableCell>
          </TableRow>
        }
      >
        {pendingReviewCount > 0 ? (
          pendingReview.map(inspection => (
            <TableRow key={inspection.id}>
              <TableCell>{inspection.id}</TableCell>
              <TableCell>
                <Link to={orgRoute(inspection.org.id)}>{inspection.org.name}</Link>
              </TableCell>
              <TableCell>{inspection.inspection_type}</TableCell>
              <TableCell>
                {inspection.inspection_date
                  ? monthDayYearDateFormat(inspection.inspection_date)
                  : 'Not set'}
              </TableCell>
              <TableCell>
                {inspection.inspection_age > 0
                  ? `${inspection.inspection_age} days old`
                  : inspection.inspection_age === 0
                  ? '< 1 day old'
                  : 'Not released'}
              </TableCell>
              <TableCell>{inspection.inspection_state}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell textCenter colSpan={6}>
              No inspections pending review.
            </TableCell>
          </TableRow>
        )}
      </Table>
      <Spacer vertical={50} />
      <Heading>Finalized Inspections</Heading>
      <Table
        className="unannounced-table"
        striped
        head={
          <TableRow>
            <TableCell heading>Inspection ID</TableCell>
            <TableCell heading>Organization</TableCell>
            <TableCell heading>Inspection Type</TableCell>
            <TableCell heading>Inspection Date</TableCell>
            <TableCell heading>Review Date</TableCell>
            <TableCell heading>Inspection Age</TableCell>
          </TableRow>
        }
      >
        {finalizedCount > 0 ? (
          finalizedInspections.map(inspection => (
            <TableRow key={inspection.id}>
              <TableCell>{inspection.id}</TableCell>
              <TableCell>
                <Link to={orgRoute(inspection.org.id)}>{inspection.org.name}</Link>
              </TableCell>
              <TableCell>{inspection.inspection_type}</TableCell>
              <TableCell>
                {inspection.inspection_date
                  ? monthDayYearDateFormat(inspection.inspection_date)
                  : 'Not set'}
              </TableCell>
              <TableCell>
                {inspection.completed_on
                  ? moment
                      .utc(inspection.completed_on)
                      .local()
                      .format('MM/DD/YYYY')
                  : 'No date'}
              </TableCell>
              <TableCell>
                {inspection.inspection_age > 0
                  ? `${inspection.inspection_age} days old`
                  : inspection.inspection_age === 0
                  ? '< 1 day old'
                  : 'Not released'}
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell textCenter colSpan={6}>
              No finalized inspections.
            </TableCell>
          </TableRow>
        )}
      </Table>
    </WidgetBody>
  </Widget>
);

export default UnannouncedInspectionReport;
