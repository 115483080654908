import { apiRequest } from 'sow/utils/api';
import { API_URL } from 'sow/constants/api';

export function getRequests(orgId, planAppId) {
  return apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/org/${orgId}/application/${planAppId}/request`,
  });
}

export function getRequestForWorksheet(orgId, planAppId, worksheetId) {
  return apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/org/${orgId}/application/${planAppId}/request/worksheet/${worksheetId}`,
  });
}

export function getRequestQuestionsForWorksheet(orgId, planAppId, worksheetId) {
  return apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/org/${orgId}/application/${planAppId}/request/worksheet/${worksheetId}/questions`,
  });
}

export function getRequestsForWorksheet(orgId, planAppId, worksheetId) {
  return apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/org/${orgId}/application/${planAppId}/request/worksheet/${worksheetId}/items`,
  });
}

export function getRequestsForApplication(orgId, planAppId) {
  return apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/org/${orgId}/application/${planAppId}/requests`,
  });
}

export function getRequestForQuestion(orgId, planAppId, questionId) {
  return apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/org/${orgId}/application/${planAppId}/request/question/${questionId}`,
  });
}

export function getActivityRequests(orgId, planAppId) {
  return apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/org/${orgId}/application/${planAppId}/request/activities`,
  });
}

export function getLocationRequests(orgId, planAppId) {
  return apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/org/${orgId}/application/${planAppId}/request/locations`,
  });
}

export function createRequest(orgId, planAppId, body) {
  return apiRequest({
    method: 'POST',
    url: `${API_URL}/v1/org/${orgId}/application/${planAppId}/request`,
    data: {
      request: body,
    },
  });
}

export function clearRequest(orgId, planAppId, requestId) {
  return apiRequest({
    method: 'DELETE',
    url: `${API_URL}/v1/org/${orgId}/application/${planAppId}/request/${requestId}`,
  });
}

export function getUserOrgRequests(params) {
  return apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/user/requests`,
    data: { ...params },
  });
}

export function getUserOrgRequestOptions() {
  return apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/user/request/options`,
  });
}

export function updateRequest(orgId, planAppId, requestId, params) {
  return apiRequest({
    method: 'PUT',
    url: `${API_URL}/v1/org/${orgId}/application/${planAppId}/request/${requestId}`,
    data: { ...params },
  });
}
