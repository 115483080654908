import React, { Fragment } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push as routerPush } from 'connected-react-router';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  orgRoute,
  attachmentOspRoute,
  certificationDocsRoute,
  exportRoute,
  orgSettingsRoute,
  orgProductsRoute,
  orgContactsRoute,
  orgLocationsRoute,
  orgTodoListRoute,
} from 'sow/routes';

import { fromPlanApp } from 'sow/store/selectors';
import * as currentOrg from 'sow/selectors/currentOrg';
import * as currentUser from 'sow/selectors/currentUser';
import Atom from 'sow/components/atoms/Atom';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import List from 'sow/components/atoms/List';
import ListItem from 'sow/components/atoms/ListItem';
import IconWithText from 'sow/components/molecules/IconWithText';
import NavLink from 'sow/components/molecules/NavLink';
import SidebarDropdown from 'sow/components/molecules/SidebarDropdown';
// import PlanAppChangeIndicator from 'sow/containers/planApp/ChangeIndicators';

const mapStateToProps = (state, props) => {
  const isAcaStaff = currentUser.isAcaStaff(state, props);
  const activeOrgs = currentUser.activeOrgs(state, props);
  const alternateFormat = !isAcaStaff && activeOrgs.length < 2;

  return {
    planAppId: fromPlanApp.planAppId(state, props),
    settings: currentOrg.settings(state, props),
    showHardCopyAccount: currentUser.showHardCopyAccount(state, props),
    isInspector: currentUser.isInspector(state, props),
    isSuperAdmin: currentUser.isSuperAdmin(state, props),
    isAcaStaff,
    alternateFormat,
  };
};

const mapDispatchToProps = {
  routerPush,
};

const SidebarOrg = ({
  org,
  routerPush,
  navClasses,
  settings,
  showHardCopyAccount,
  isInspector,
  isAcaStaff,
  alternateFormat,
}) => (
  <List className={classnames(alternateFormat ? '' : 'sidebar-submenu', navClasses)}>
    <ListItem className={alternateFormat ? 'sidebar-heading' : 'org-heading'}>
      <Atom style={{ cursor: 'pointer' }} onClick={() => routerPush(orgRoute(org.id))}>
        {org.name}
      </Atom>
    </ListItem>

    {showHardCopyAccount &&
      !R.isEmpty(settings) &&
      settings.aca_byid[org.aca_id].hard_copy && (
        <ListItem className="sidebar-hard-copy">
          <Atom>HARD COPY ACCOUNT</Atom>
        </ListItem>
      )}

    <NavLink to={orgRoute(org.id)} exact>
      <IconWithText
        icon={<FontAwesome icon="tachometer-alt-average" />}
        text="Dashboard"
      />
    </NavLink>

    <NavLink to={attachmentOspRoute(org.id)}>
      <IconWithText icon={<FontAwesome icon="paperclip" />} text="Attachments" />
    </NavLink>

    <NavLink to={certificationDocsRoute(org.id)}>
      <IconWithText
        icon={<FontAwesome variant="far" icon="file-alt" />}
        text="Certification Docs"
      />
    </NavLink>

    <NavLink to={exportRoute(org.id)}>
      <IconWithText icon={<FontAwesome icon="download" />} text="Export" />
    </NavLink>

    {isAcaStaff && !isInspector && (
      <NavLink to={orgProductsRoute(org.id)} exact>
        <IconWithText
          icon={<FontAwesome icon="boxes" />}
          text={'Organization Products'}
        />
      </NavLink>
    )}

    <NavLink to={orgContactsRoute(org.id)}>
      <IconWithText icon={<FontAwesome icon="address-book" />} text="Contacts" />
    </NavLink>

    <NavLink to={orgLocationsRoute(org.id)}>
      <IconWithText icon={<FontAwesome icon="map-marker-alt" />} text="Locations" />
    </NavLink>
    {!isInspector && (
      <Fragment>
        <NavLink to={orgTodoListRoute(org.id)}>
          <IconWithText
            icon={<FontAwesome icon="list" />}
            text="Organization To-do List"
          />
        </NavLink>
        <NavLink to={orgSettingsRoute(org.id)}>
          <IconWithText icon={<FontAwesome icon="cog" />} text="Organization Settings" />
        </NavLink>
      </Fragment>
    )}
  </List>
);

SidebarOrg.propTypes = {
  org: PropTypes.object,
  router: PropTypes.object,
  navClasses: PropTypes.string,
  routerPush: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SidebarOrg);
