import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { clearInspectionReportFilters, fetchUnannouncedReport } from 'sow/actions/aca';
import { API_URL } from 'sow/constants/api';
import actions from 'sow/actions/pure';
import * as currentAca from 'sow/selectors/currentAca';

import UnannouncedInspectionReportFilters from 'sow/components/organisms/UnannouncedInspectionReportFilters';

const emptyForm = {};

const mapStateToProps = (state, props) => ({
  acaId: currentAca.acaId(state, props),
  filterOptions: state.inspection.unannouncedFilterOptions,
  hasResults:
    !state.inspectionReport.isFetching &&
    state.inspectionReport.unannouncedReportGenerated,
});

const mapDispatchToProps = {
  clearInspectionReportFilters,
  fetchUnannouncedReport,
  fetchOptions: actions.inspection.getUnannouncedOptions,
};

class UnannouncedInspectionReportFiltersContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { initialValues: R.clone(emptyForm) };
  }

  componentDidMount() {
    this.props.fetchOptions();
  }

  componentWillUnmount() {
    this.props.clearInspectionReportFilters();
  }

  handleSearch(values, formikBag) {
    this.props.fetchUnannouncedReport(this.props.acaId, values);
  }

  resetForm(values, formikBag) {
    this.setState({ initialValues: { ...emptyForm } });
    formikBag.resetForm({});
    this.props.clearInspectionReportFilters();
  }

  exportReport({ inspection_year }) {
    const { acaId } = this.props;
    const params = `inspection_year=${inspection_year}`;
    window.open(
      `${API_URL}/v1/aca/${acaId}/unannounced_report/export?${params}`,
      '_blank',
    );
  }

  render() {
    return (
      <UnannouncedInspectionReportFilters
        initialValues={this.state.initialValues}
        handleSearch={this.handleSearch.bind(this)}
        resetForm={this.resetForm.bind(this)}
        exportReport={this.exportReport.bind(this)}
        {...this.props}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(UnannouncedInspectionReportFiltersContainer);
