import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import PriorityDesignationTypeListContainer from 'sow/containers/PriorityDesignationTypeListContainer';

const PriorityDesignationTypeListPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <PriorityDesignationTypeListContainer />}
  </AdminPageTemplate>
);

export default PriorityDesignationTypeListPage;
