const mark = action => `PRIORITY_DESIGNATION_TYPE/${action}`;

export const LOAD_PRIORITY_DESIGNATION_TYPES = mark('LOAD_PRIORITY_DESIGNATION_TYPES');
export const FETCH_BY_ID = mark('FETCH_BY_ID');
export const ADD_PRIORITY_DESIGNATION_TYPE = mark('ADD_PRIORITY_DESIGNATION_TYPE');
export const UPDATE_PRIORITY_DESIGNATION_TYPE = mark('UPDATE_PRIORITY_DESIGNATION_TYPE');
export const UPDATE_SORT_ORDER = mark('UPDATE_SORT_ORDER');
export const DELETE_PRIORITY_DESIGNATION_TYPE = mark('DELETE_PRIORITY_DESIGNATION_TYPE');

export const loadPriorityDesignationTypes = (payload, onSuccess) => ({
  type: LOAD_PRIORITY_DESIGNATION_TYPES,
  payload,
  meta: { onSuccess },
});

export const fetchById = (payload, onSuccess) => ({
  type: FETCH_BY_ID,
  payload,
  meta: { onSuccess },
});

export const addPriorityDesignationType = (payload, onSuccess) => ({
  type: ADD_PRIORITY_DESIGNATION_TYPE,
  payload,
  meta: { onSuccess },
});

export const updatePriorityDesignationType = (id, payload, onSuccess) => ({
  type: UPDATE_PRIORITY_DESIGNATION_TYPE,
  id,
  payload,
  meta: { onSuccess },
});

export const updateSortOrder = (payload, onSuccess) => ({
  type: UPDATE_SORT_ORDER,
  payload,
  meta: { onSuccess },
});

export const deletePriorityDesignationType = (id, onSuccess) => ({
  type: DELETE_PRIORITY_DESIGNATION_TYPE,
  id,
  meta: { onSuccess },
});
