import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { push as routerPush } from 'connected-react-router';

import { itemSectionTypeRoute } from 'sow/routes';
import * as itemSectionTypeActions from 'sow/actions/pure/itemSectionType';

import ItemSectionTypeEdit from 'sow/components/organisms/ItemSectionTypeEdit';

const mapStateToProps = (state, props) => ({
  isNew: R.propEq('id', 'new')(props.match.params),
  itemSectionTypeId: R.path(['match', 'params', 'id'], props),
});

const mapDispatchToProps = {
  fetchItemSectionType: itemSectionTypeActions.fetchById,
  addItemSectionType: itemSectionTypeActions.addItemSectionType,
  updateItemSectionType: itemSectionTypeActions.updateItemSectionType,
  deleteItemSectionType: itemSectionTypeActions.deleteItemSectionType,
  redirect: routerPush,
};

const initialState = { isLoading: true, initialValues: { sort_order: 1, enabled: true } };

class ItemSectionTypeEditContainer extends Component {
  constructor(props) {
    super(props);

    this.state = R.clone(initialState);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.props.isNew) {
      this.props.fetchItemSectionType(
        this.props.itemSectionTypeId,
        this.setInitialValues.bind(this),
      );
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState({ id }) {
    if (this.props.isNew) {
      this.props.history.push(`/admin/system-admin/item/item-section-type/${id}`);
    }
  }

  redirectToList() {
    this.props.redirect(itemSectionTypeRoute());
  }

  setInitialValues(data) {
    if (this._isMounted) {
      this.setState({
        isLoading: false,
        initialValues: {
          sort_order: data.sort_order,
          name: data.name,
          enabled: data.enabled,
        },
      });
    }
  }

  onSave(values, formikBag) {
    const {
      isNew,
      itemSectionTypeId,
      addItemSectionType,
      updateItemSectionType,
    } = this.props;
    if (isNew) {
      addItemSectionType(values, this.updateState.bind(this));
    } else {
      updateItemSectionType(itemSectionTypeId, values);
    }
  }

  onDelete() {
    const { itemSectionTypeId, deleteItemSectionType } = this.props;
    deleteItemSectionType(itemSectionTypeId, this.redirectToList.bind(this));
  }

  render() {
    return (
      <ItemSectionTypeEdit
        onSave={this.onSave.bind(this)}
        onDelete={this.onDelete.bind(this)}
        {...this.props}
        {...this.state}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ItemSectionTypeEditContainer);
