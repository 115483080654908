import React from 'react';

import OrgPageTemplate from 'sow/containers/OrgPageTemplate';
import TodoListFiltersContainer from 'sow/containers/TodoListFiltersContainer';
import TodoListTableContainer from 'sow/containers/TodoListTableContainer';

const TodoListOrgPage = () => (
  <OrgPageTemplate title="Organization To-do List">
    <TodoListFiltersContainer orgLevelPage />
    <TodoListTableContainer />
  </OrgPageTemplate>
);

export default TodoListOrgPage;
