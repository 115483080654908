import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import {
  fetchInspectionReport,
  fetchInspectionReportOptions,
  clearInspectionReportFilters,
} from 'sow/actions/aca';
import { API_URL } from 'sow/constants/api';
import * as currentAca from 'sow/selectors/currentAca';
import * as sowTypes from 'sow/types';

import AllInspectionReportFilters from 'sow/components/organisms/AllInspectionReportFilters';

const paramsForURL = filters => queryString.stringify(filters, { arrayFormat: 'comma' });

const paramsForExport = filters =>
  queryString.stringify(filters, { arrayFormat: 'bracket' });

const cleanFilters = filters => {
  let cleanedFilters = {};
  for (let key in filters) {
    switch (R.type(filters[key])) {
      case 'Object':
        const cleanedObj = cleanRangeFilter(filters[key]);
        if (R.isEmpty(cleanedObj)) {
          break;
        } else {
          cleanedFilters = { ...cleanedFilters, ...cleanedObj };
        }
        break;
      case 'String':
      case 'Number':
      case 'Boolean':
        if (R.isEmpty(filters[key])) {
          break;
        } else {
          cleanedFilters[key] = filters[key];
          break;
        }
      case 'Array':
        cleanedFilters[key] = cleanMultiFilter(filters[key]);
        break;
      default:
        break;
    }
  }
  return cleanedFilters;
};

const cleanRangeFilter = rangeObj => {
  const cleanObj = {};
  for (let key in rangeObj) {
    switch (R.isEmpty(rangeObj[key])) {
      case true:
        break;
      default:
        cleanObj[key] = rangeObj[key];
        break;
    }
  }
  return cleanObj;
};

const cleanMultiFilter = list =>
  R.map(filter => {
    if (R.has('value', filter)) {
      return filter.value;
    } else {
      return filter;
    }
  }, list);

const emptyInitialValues = {
  anniversary_date_range: {
    anniversary_date_start: '',
    anniversary_date_end: '',
  },
  inspection_date_range: {
    inspection_date_start: '',
    inspection_date_end: '',
  },
};

const mapStateToProps = (state, props) => ({
  acaId: currentAca.acaId(state, props),
  fetchingOptions: state.inspectionReport.fetchingOptions,
  hasResults: state.inspectionReport.hasResults,
  filterOptions: state.inspectionReport.filterOptions,
});

const mapDispatchToProps = {
  fetchInspectionReport,
  fetchInspectionReportOptions,
  clearInspectionReportFilters,
};

class AllInspectionReportFiltersContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValues: R.clone(emptyInitialValues),
    };
  }

  componentDidMount() {
    const { acaId, fetchInspectionReportOptions } = this.props;
    fetchInspectionReportOptions(acaId);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.fetchingOptions !== this.props.fetchingOptions &&
      !R.isNil(this.props.filterOptions)
    ) {
      const urlFilters = queryString.parse(this.props.location.search, {
        arrayFormat: 'comma',
      });
      this.setInitialValues(urlFilters);
    }
  }

  componentWillUnmount() {
    this.props.clearInspectionReportFilters();
  }

  setInitialValues(urlFilters) {
    const { initialValues } = this.state;

    for (let key in urlFilters) {
      switch (key) {
        case 'inspection_state':
        case 'category':
        case 'sample_type':
        case 'inspectors':
        case 'certifications':
          if (R.type(urlFilters[key]) === 'String') {
            initialValues[key] = [urlFilters[key]];
            break;
          } else {
            initialValues[key] = urlFilters[key];
          }
        case 'inspection_date_start':
        case 'inspection_date_end':
          initialValues.inspection_date_range[key] = urlFilters[key];
          break;
        case 'anniversary_date_start':
        case 'anniversary_date_end':
          initialValues.anniversary_date_range[key] = urlFilters[key];
          break;
        default:
          initialValues[key] = urlFilters[key];
          break;
      }
    }
    this.setState({ initialValues: { ...initialValues } });
    if (!R.isEmpty(urlFilters)) {
      const cleanedFilters = cleanFilters(initialValues);
      this.props.fetchInspectionReport(this.props.acaId, cleanedFilters);
    }
  }

  handleSearch(values, formikBag) {
    const { acaId, fetchInspectionReport } = this.props;
    const cleanedFilters = cleanFilters(values);
    const params = paramsForURL(cleanedFilters);

    if (params) {
      history.pushState(null, null, `?${params}`);
    }
    fetchInspectionReport(acaId, cleanedFilters);
  }

  resetFilters(values, formikBag) {
    const { location, clearInspectionReportFilters } = this.props;

    this.setState({ initialValues: { ...emptyInitialValues } }, () => {
      clearInspectionReportFilters();
      history.pushState(null, null, location.pathname);
      formikBag.resetForm({ ...emptyInitialValues });
    });
  }

  exportList(values) {
    const { acaId } = this.props;
    const cleanedFilters = cleanFilters(values);
    const params = paramsForExport(cleanedFilters);
    window.open(
      `${API_URL}/v1/aca/${acaId}/inspection_report/export?${params}`,
      '_blank',
    );
  }

  render() {
    return (
      <AllInspectionReportFilters
        handleSearch={this.handleSearch.bind(this)}
        resetFilters={this.resetFilters.bind(this)}
        exportList={this.exportList.bind(this)}
        initialValues={this.state.initialValues}
        {...this.props}
      />
    );
  }
}

AllInspectionReportFiltersContainer.propTypes = {
  acaId: sowTypes.acaIdType,
  fetchInspectionReport: PropTypes.func,
  clearInspectionReportFilters: PropTypes.func,
  fetchingOptions: PropTypes.bool,
  filterOptions: PropTypes.object,
};

AllInspectionReportFiltersContainer.defaultProps = {
  acaId: null,
  fetchingOptions: true,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AllInspectionReportFiltersContainer);
