import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { planAppFlagAbbreviations, planAppFlags } from 'sow/constants/planApp';
import Tooltip from 'sow/components/molecules/Tooltip';

const Flag = styled.div`
  & {
    top: -7px;
    right: -5px;
    position: relative;
    text-align: right;
    float: right;
    display: inline;
    color: white;
    font-size: 10px;
    padding: 1px 5px;
    height: 15px;
    background-color: ${props => getColor(props)};
    border-right: 1px solid #eeeeee;
  }

  &:before {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    width: 0;
    height: 0;
    border-right: 10px solid ${props => getColor(props)};
    border-bottom: 8px solid transparent;
  }

  &:after {
    content: '';
    position: absolute;
    right: 100%;
    top: 7px;
    width: 0;
    height: 0;
    border-right: 10px solid ${props => getColor(props)};
    border-top: 8px solid transparent;
  }
`;

const getColor = props => {
  const { grey, green, red, blue, hardCopy } = props;
  if (grey) return 'grey';
  if (green) return '#4cae4c';
  if (blue) return '#4478b6';
  if (red) return '#cf2b1f';
  if (hardCopy) return '#ff0000';
  else return 'orange';
};

const PriorityFlag = ({ flags, isInspector, isClient }) => {
  let newFlag = null;
  let expFlag = null;
  let medFlag = null;
  let invFlag = null;
  let ncFlag = null;
  let hardCopyFlag = null;
  let olpsFlag = null;

  if (flags[planAppFlags.IS_EXPEDITED]) {
    expFlag = (
      <Tooltip key={'expFlag'} overlay="Expedited">
        <Flag>{planAppFlagAbbreviations[planAppFlags.IS_EXPEDITED]}</Flag>
      </Tooltip>
    );
  }

  if (flags[planAppFlags.IS_NONCOMPLIANT]) {
    ncFlag = (
      <Tooltip key={'ncFlag'} overlay="Noncompliance">
        <Flag red>{planAppFlagAbbreviations[planAppFlags.IS_NONCOMPLIANT]}</Flag>
      </Tooltip>
    );
  }

  if (flags[planAppFlags.IS_IN_MEDIATION]) {
    medFlag = (
      <Tooltip key={'medFlag'} overlay="Settlement Agreement">
        <Flag>{planAppFlagAbbreviations[planAppFlags.IS_IN_MEDIATION]}</Flag>
      </Tooltip>
    );
  }

  if (!isClient) {
    if (flags[planAppFlags.IS_UNDER_INVESTIGATION]) {
      invFlag = (
        <Tooltip key={'invFlag'} overlay="Under Investigation">
          <Flag red>{planAppFlagAbbreviations[planAppFlags.IS_UNDER_INVESTIGATION]}</Flag>
        </Tooltip>
      );
    }
  }

  if (!isInspector) {
    if (flags[planAppFlags.IS_NEW]) {
      newFlag = (
        <Flag green key={'newFlag'}>
          {planAppFlagAbbreviations[planAppFlags.IS_NEW]}
        </Flag>
      );
    }
  }

  if (flags[planAppFlags.IS_HARD_COPY]) {
    hardCopyFlag = (
      <Tooltip key={'hardCopyFlag'} overlay="Hard Copy Account">
        <Flag hardCopy>{planAppFlagAbbreviations[planAppFlags.IS_HARD_COPY]}</Flag>
      </Tooltip>
    );
  }

  if (flags[planAppFlags.IS_OLPS]) {
    olpsFlag = (
      <Flag key={'olpsFlag'}>{planAppFlagAbbreviations[planAppFlags.IS_OLPS]}</Flag>
    );
  }

  return (
    <Fragment>
      {[newFlag, olpsFlag, expFlag, medFlag, invFlag, ncFlag, hardCopyFlag]}
    </Fragment>
  );
};

PriorityFlag.propTypes = {
  flags: PropTypes.object,
};

export default PriorityFlag;
