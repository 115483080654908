import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  acaRoute,
  acaActivityRoute,
  acaCertificationDocsRoute,
  acaOspBaseRoute,
  acaOrgBaseRoute,
  acaSettingsRoute,
  acaProductsSearchRoute,
  acaReportsRoute,
  inspectionReportRoute,
  locationReportRoute,
  workflowManagerRoute,
  certificationSpecialistRoute,
  acaTodoListRoute,
} from 'sow/routes';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Atom from 'sow/components/atoms/Atom';
import Block from 'sow/components/atoms/Block';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import List from 'sow/components/atoms/List';
import ListItem from 'sow/components/atoms/ListItem';
import SidebarOrg from 'sow/components/organisms/SidebarOrg';
import IconWithText from 'sow/components/molecules/IconWithText';
import SidebarLink from 'sow/components/molecules/SidebarLink';
import NavLink from 'sow/components/molecules/NavLink';

const SidebarAcaAdmin = ({
  aca,
  org,
  routerPush,
  isAcaOwner,
  isSuperAdmin,
  isWorkflowManager,
  isCertificationSpecialist,
  navClasses,
}) => (
  <Block className="sidebar-aca-admin">
    <List className={navClasses}>
      <ListItem className="sidebar-heading">
        <Atom
          style={{ cursor: 'pointer' }}
          onClick={() => routerPush(workflowManagerRoute(aca.id))}
        >
          {aca.name}
        </Atom>
      </ListItem>

      {/* <SidebarLink to={acaRoute(aca.id)} exact>
        <IconWithText icon={<FontAwesome icon="star" />} text="Dashboard" />
      </SidebarLink> */}

      {isWorkflowManager && (
        <SidebarLink to={workflowManagerRoute(aca.id)} exact>
          <IconWithText icon={<FontAwesome icon="stream" />} text="Workflow Dashboard" />
        </SidebarLink>
      )}

      {isCertificationSpecialist && (
        <SidebarLink to={certificationSpecialistRoute(aca.id)}>
          <IconWithText icon={<FontAwesome icon="stream" />} text="Workflow Dashboard" />
        </SidebarLink>
      )}

      <SidebarLink to={acaTodoListRoute(aca.id)}>
        <IconWithText icon={<FontAwesome icon="list" />} text="To-do List" />
      </SidebarLink>

      <SidebarLink to={acaOspBaseRoute(aca.id)}>
        <IconWithText icon={<FontAwesome icon="list-alt" />} text="Plan" />
      </SidebarLink>

      <SidebarLink to={acaCertificationDocsRoute(aca.id)}>
        <IconWithText
          icon={<FontAwesome variant="far" icon="file-alt" />}
          text="Certification Docs"
        />
      </SidebarLink>

      {org ? (
        <NavLink
          to={acaOrgBaseRoute(aca.id)}
          isActive={() => true}
          className={'side-menu-org-active'}
        >
          <Row>
            <Column xs={2}>
              <FontAwesome icon="users" />
            </Column>
            <Column xs={8}>Organizations</Column>
            <Column xs={2}>
              <FontAwesome icon="caret-up" />
            </Column>
          </Row>
        </NavLink>
      ) : (
        <SidebarLink to={acaOrgBaseRoute(aca.id)}>
          <IconWithText icon={<FontAwesome icon="users" />} text="Organizations" />
        </SidebarLink>
      )}
    </List>

    {org && (
      <SidebarOrg
        aca={aca}
        org={org}
        navClasses={navClasses}
        isWorkflowManager={isWorkflowManager}
      />
    )}

    <List className={navClasses}>
      <SidebarLink to={acaActivityRoute(aca.id)}>
        <IconWithText icon={<FontAwesome icon="list" />} text="Activity Log" />
      </SidebarLink>

      {(isSuperAdmin || isAcaOwner) && (
        <SidebarLink to={acaSettingsRoute(aca.id)}>
          <IconWithText icon={<FontAwesome icon="cog" />} text="Settings" />
        </SidebarLink>
      )}

      <SidebarLink to={acaProductsSearchRoute(aca.id)} exact>
        <IconWithText icon={<FontAwesome icon="boxes" />} text="Products" />
      </SidebarLink>

      {isSuperAdmin && (
        <SidebarLink to={acaReportsRoute(aca.id)}>
          <IconWithText icon={<FontAwesome icon="chart-bar" />} text="Reports" />
        </SidebarLink>
      )}

      {isSuperAdmin && (
        <SidebarLink to={inspectionReportRoute(aca.id)}>
          <IconWithText icon={<FontAwesome icon="circle-i" />} text="Inspections" />
        </SidebarLink>
      )}

      {isSuperAdmin && (
        <SidebarLink to={locationReportRoute(aca.id)}>
          <IconWithText icon={<FontAwesome icon="map-marker-alt" />} text="Locations" />
        </SidebarLink>
      )}
    </List>
  </Block>
);

SidebarAcaAdmin.propTypes = {
  aca: PropTypes.object,
  org: PropTypes.object,
  routerPush: PropTypes.func.isRequired,
  isAcaOwner: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  isWorkflowManager: PropTypes.bool,
  isCertificationSpecialist: PropTypes.bool,
  navClasses: PropTypes.string,
};

export default SidebarAcaAdmin;
