import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as adminRequestActions from 'sow/actions/adminRequest';
import * as currentUser from 'sow/selectors/currentUser';

import TodoListTable from 'sow/components/organisms/TodoListTable';
import Spinner from 'sow/components/atoms/Spinner';

const mapStateToProps = (state, props) => ({
  isStaff: currentUser.isStaff(state, props),
  isStandardClient: currentUser.isStandardClient(state, props),
  isFetchingOptions: R.path(['adminRequests', 'todoListFilters', 'isFetching'], state),
  isFetchingList: R.path(['adminRequests', 'todoList', 'isFetching'], state),
  todoList: R.path(['adminRequests', 'todoList', 'list'], state),
  listFilters: R.path(['adminRequests', 'reportFilters'], state),
  priorityDesignationTypeOptions: R.path(
    [
      'adminRequests',
      'todoListFilters',
      'filterOptions',
      'priority_designation_type_options',
    ],
    state,
  ),
});

const mapDispatchToProps = { updateRequest: adminRequestActions.updateRequestData };

class TodoListTableContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  toggleModal(event, requestData) {
    event.preventDefault();
    const { show } = this.state;

    if (requestData) {
      this.setState({
        show: !show,
        requestId: requestData.id,
        orgId: requestData.worksheet.organization_id,
        planAppId: requestData.worksheet.application_id,
        modalData: {
          priority_designation_type_id: requestData.priority_designation_type.id,
          due_date: requestData.due_date,
        },
      });
    } else {
      this.setState({
        show: !show,
        requestId: null,
        orgId: null,
        planAppId: null,
        modalData: null,
      });
    }
  }

  updateRequest(values) {
    const { requestId, orgId, planAppId } = this.state;
    values.list_filters = this.props.listFilters;
    if (requestId) {
      this.props.updateRequest(orgId, planAppId, requestId, values);
    }
    this.setState({ show: false });
  }

  render() {
    if (this.props.isFetchingOptions) return null;
    if (this.props.isFetchingList) return <Spinner />;
    return (
      <TodoListTable
        todoList={this.props.todoList}
        toggleModal={this.toggleModal.bind(this)}
        priorityDesignationTypeOptions={this.props.priorityDesignationTypeOptions}
        updateRequest={this.updateRequest.bind(this)}
        modalData={this.state.modalData}
        isStaff={this.props.isStaff}
        isStandardClient={this.props.isStandardClient}
        {...this.state}
      />
    );
  }
}

TodoListTableContainer.propTypes = {
  isFetchingOptions: PropTypes.bool,
  isFetchingList: PropTypes.bool,
  todoList: PropTypes.array,
};

TodoListTableContainer.defaultProps = {
  isFetchingOptions: true,
  isFetchingList: true,
  todoList: [],
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(TodoListTableContainer);
