import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import * as priorityDesignationTypeActions from 'sow/actions/pure/priorityDesignationType';
import { apiGet, apiPost, apiPut } from 'sow/sagas/api';

export const loadPriorityDesignationTypes = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/priority_designation_type', payload);
    if (response && meta.onSuccess) {
      meta.onSuccess(response.data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchById = function*({ payload, meta }) {
  try {
    const { response } = yield call(
      apiGet,
      `/admin/priority_designation_type/${payload}`,
    );

    if (response && meta.onSuccess) {
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addPriorityDesignationType = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiPost, '/admin/priority_designation_type', payload);
    if (response && meta.onSuccess) {
      yield put(actions.shell.toast('success', 'Priority designation type created.'));
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updatePriorityDesignationType = function*({ id, payload, meta }) {
  try {
    const { response } = yield call(
      apiPut,
      `/admin/priority_designation_type/${id}`,
      payload,
    );
    if (response) {
      yield put(actions.shell.toast('success', 'Priority designation type updated.'));
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateSortOrder = function*({ payload }) {
  try {
    yield call(apiPost, '/admin/priority_designation_type/update_order', {
      updated_list: payload,
    });
  } catch (error) {
    console.error(error);
  }
};

export const deletePriorityDesignationType = function*({ id, meta }) {
  try {
    const { response } = yield call(
      apiPut,
      `/admin/priority_designation_type/delete/${id}`,
    );
    if (response) {
      yield put(actions.shell.toast('success', 'Priority designation type deleted.'));
      if (meta.onSuccess) meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(
    priorityDesignationTypeActions.LOAD_PRIORITY_DESIGNATION_TYPES,
    loadPriorityDesignationTypes,
  );
  yield takeEvery(priorityDesignationTypeActions.FETCH_BY_ID, fetchById);
  yield takeEvery(
    priorityDesignationTypeActions.ADD_PRIORITY_DESIGNATION_TYPE,
    addPriorityDesignationType,
  );
  yield takeEvery(
    priorityDesignationTypeActions.UPDATE_PRIORITY_DESIGNATION_TYPE,
    updatePriorityDesignationType,
  );
  yield takeEvery(priorityDesignationTypeActions.UPDATE_SORT_ORDER, updateSortOrder);
  yield takeEvery(
    priorityDesignationTypeActions.DELETE_PRIORITY_DESIGNATION_TYPE,
    deletePriorityDesignationType,
  );
}
