import {
  FETCH_INSPECTION_REPORT,
  FETCH_INSPECTION_REPORT_SUCCESS,
  FETCH_INSPECTION_REPORT_FAIL,
  FETCH_INSPECTION_REPORT_OPTIONS,
  FETCH_INSPECTION_REPORT_OPTIONS_SUCCESS,
  FETCH_INSPECTION_REPORT_OPTIONS_FAIL,
  CLEAR_INSPECTION_REPORT_FILTER,
  FETCH_UNANNOUNCED_REPORT,
  FETCH_UNANNOUNCED_REPORT_SUCCESS,
  FETCH_UNANNOUNCED_REPORT_FAIL,
} from 'sow/actions/aca';

const initialState = {
  searchInitiated: false,
  unannouncedReportGenerated: false,
  hasResults: false,
};

export default function inspectionReport(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_INSPECTION_REPORT:
      return { ...state, searchInitiated: true, isFetching: true };

    case FETCH_INSPECTION_REPORT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: payload.list,
        hasResults: payload.hasResults,
      };

    case FETCH_INSPECTION_REPORT_FAIL:
      return state;

    case FETCH_INSPECTION_REPORT_OPTIONS:
      return { ...state, fetchingOptions: true };

    case FETCH_INSPECTION_REPORT_OPTIONS_SUCCESS:
      return { ...state, fetchingOptions: false, filterOptions: payload };

    case FETCH_INSPECTION_REPORT_OPTIONS_FAIL:
      return { ...state, fetchingOptions: false };

    case CLEAR_INSPECTION_REPORT_FILTER:
      return {
        ...state,
        searchInitiated: false,
        hasResults: false,
        unannouncedReportGenerated: false,
        list: [],
      };

    case FETCH_UNANNOUNCED_REPORT:
      return { ...state, unannouncedReportGenerated: true, isFetching: true };

    case FETCH_UNANNOUNCED_REPORT_SUCCESS:
      return {
        ...state,
        inspectionYearData: payload.inspection_year_data,
        completedInspections: payload.completed_inspections,
        pendingInspections: payload.pending_inspections,
        pendingReview: payload.pending_review,
        finalizedInspections: payload.finalized_inspections,
        completedCount: payload.completed_inspections.length,
        pendingCount: payload.pending_inspections.length,
        pendingReviewCount: payload.pending_review.length,
        finalizedCount: payload.finalized_inspections.length,
        isFetching: false,
      };

    case FETCH_UNANNOUNCED_REPORT_FAIL:
      return state;

    default:
      return state;
  }
}
