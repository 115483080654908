import React from 'react';

import './OspNotification.module.css';
import { ospNotificationBanners } from 'sow/constants/planApp';
import Block from 'sow/components/atoms/Block';
import Glyphicon from 'sow/components/molecules/Glyphicon';
import FontAwesome from 'sow/components/atoms/FontAwesome';

const OspNotification = ({ banner, nonurgent }) => (
  <Block className={nonurgent ? 'banner-container nonurgent' : 'banner-container'}>
    <Block className="banner-header">
      {nonurgent ? (
        <FontAwesome className="banner-icon" icon="circle-exclamation" />
      ) : (
        <Glyphicon className="banner-icon" glyph="exclamation-sign" />
      )}
      {ospNotificationBanners[banner].header}
    </Block>
    {ospNotificationBanners[banner].body}
  </Block>
);

export default OspNotification;
