import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { push as routerPush } from 'connected-react-router';

import { priorityDesignationTypeRoute } from 'sow/routes';
import * as priorityDesignationTypeActions from 'sow/actions/pure/priorityDesignationType';

import PriorityDesignationTypeEdit from 'sow/components/organisms/PriorityDesignationTypeEdit';

const mapStateToProps = (state, props) => ({
  isNew: R.propEq('id', 'new')(props.match.params),
  priorityDesignationTypeId: R.path(['match', 'params', 'id'], props),
});

const mapDispatchToProps = {
  fetchPriorityDesignationType: priorityDesignationTypeActions.fetchById,
  addPriorityDesignationType: priorityDesignationTypeActions.addPriorityDesignationType,
  updatePriorityDesignationType:
    priorityDesignationTypeActions.updatePriorityDesignationType,
  deletePriorityDesignationType:
    priorityDesignationTypeActions.deletePriorityDesignationType,
  redirect: routerPush,
};

const initialState = {
  isLoading: true,
  initialValues: {
    sort_order: 1,
    enabled: true,
    primary_color: '#000000',
    secondary_color: '#000000',
  },
};

class PriorityDesignationTypeEditContainer extends Component {
  constructor(props) {
    super(props);

    this.state = R.clone(initialState);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.props.isNew) {
      this.props.fetchPriorityDesignationType(
        this.props.priorityDesignationTypeId,
        this.setInitialValues.bind(this),
      );
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState({ id }) {
    if (this.props.isNew) {
      this.props.history.push(
        `/admin/system-admin/object/priority-designation-type/${id}`,
      );
    }
  }

  redirectToList() {
    this.props.redirect(priorityDesignationTypeRoute());
  }

  setInitialValues(data) {
    if (this._isMounted) {
      this.setState({
        isLoading: false,
        initialValues: {
          sort_order: data.sort_order,
          name: data.name,
          enabled: data.enabled,
          primary_color: data.primary_color,
          secondary_color: data.secondary_color,
        },
      });
    }
  }

  onSave(values, formikBag) {
    const {
      isNew,
      priorityDesignationTypeId,
      addPriorityDesignationType,
      updatePriorityDesignationType,
    } = this.props;
    if (isNew) {
      addPriorityDesignationType(values, this.updateState.bind(this));
    } else {
      updatePriorityDesignationType(priorityDesignationTypeId, values);
    }
  }

  onDelete() {
    const { priorityDesignationTypeId, deletePriorityDesignationType } = this.props;
    deletePriorityDesignationType(
      priorityDesignationTypeId,
      this.redirectToList.bind(this),
    );
  }

  render() {
    return (
      <PriorityDesignationTypeEdit
        onSave={this.onSave.bind(this)}
        onDelete={this.onDelete.bind(this)}
        {...this.props}
        {...this.state}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PriorityDesignationTypeEditContainer);
