import React from 'react';

import AcaPageTemplate from 'sow/containers/AcaPageTemplate';
import TodoListFiltersContainer from 'sow/containers/TodoListFiltersContainer';
import TodoListTableContainer from 'sow/containers/TodoListTableContainer';

const TodoListAcaPage = () => (
  <AcaPageTemplate title="To-do List">
    <TodoListFiltersContainer />
    <TodoListTableContainer />
  </AcaPageTemplate>
);

export default TodoListAcaPage;
