import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as adminRequestActions from 'sow/actions/adminRequest';
import * as currentUser from 'sow/selectors/currentUser';
import { resourceUpdate } from 'sow/store/helpers';
import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';
import WorksheetMatrixRow from 'sow/components/organisms/WorksheetMatrix/WorksheetMatrixRow';
import Spinner from 'sow/components/atoms/Spinner';

const acceptChangeResource = (
  orgId,
  planAppId,
  changeRequestId,
  worksheetId,
  matrixRowId,
) =>
  resourceUpdate(
    `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/ws_changes/${worksheetId}/matrix_row/${matrixRowId}/accept_all`,
    'changeRequestOverviewResp',
  );

const discardChangeResource = (
  orgId,
  planAppId,
  changeRequestId,
  worksheetId,
  matrixRowId,
) =>
  resourceUpdate(
    `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/ws_changes/${worksheetId}/matrix_row/${matrixRowId}/reject_all`,
    'changeRequestOverviewResp',
  );

const mapStateToProps = (state, props) => ({
  rowDeletionChange: fromPlanApp.matrixRowDeletionChange(state, props),
  changeList: fromPlanApp.worksheetChangeList(state, props),
  isCSStaff: currentUser.isCSStaff(state, props),
  isInspector: currentUser.isInspector(state, props),
});

const mapDispatchToProps = dispatch => ({
  acceptChange: action => dispatch(action(null)),
  rejectChange: action => dispatch(action(null)),
  refreshAdminRequests: (orgId, planAppId, locationId) =>
    adminRequestActions.refreshAdminRequests(orgId, planAppId, locationId)(dispatch),
});

class MatrixRowContainer extends Component {
  constructor() {
    super();
    this.state = { isLoading: false };
  }

  isAccepted = R.allPass([
    R.pipe(R.prop('state'), R.contains(R.__, ['accepted', 'applied'])),
  ]);

  render() {
    const {
      orgId,
      planAppId,
      changeList,
      matrixRowId,
      acceptChange,
      rejectChange,
      rowDeletionChange,
      worksheetId,
      changeRequest,
      refreshAdminRequests,
    } = this.props;

    const isRowDeleted = R.allPass([
      change => !!change,
      R.pipe(R.prop('state'), R.contains(R.__, ['accepted', 'applied'])),
    ])(rowDeletionChange);

    const getMatrixChanges = () =>
      R.filter(R.propEq('matrixRowId', matrixRowId))(changeList);
    const getPendingChangesForWorksheet = () =>
      R.filter(R.propEq('state', 'open'))(getMatrixChanges());
    const hasPendingChangesForWorksheet = R.not(
      R.isEmpty(getPendingChangesForWorksheet()),
    );
    const locationId = R.pathOr(null, ['landId'], getPendingChangesForWorksheet()[0]);

    const acceptAll = () => {
      if (hasPendingChangesForWorksheet) {
        const { action } = acceptChangeResource(
          orgId,
          planAppId,
          changeRequest.id,
          worksheetId,
          matrixRowId,
        );
        acceptChange(action).then(() =>
          refreshAdminRequests(orgId, planAppId, locationId),
        );
      }
    };

    const discardAll = () => {
      if (hasPendingChangesForWorksheet) {
        const { action } = discardChangeResource(
          orgId,
          planAppId,
          changeRequest.id,
          worksheetId,
          matrixRowId,
        );
        rejectChange(action);
      }
    };

    if (this.state.isLoading) return <Spinner />;

    return (
      <WorksheetMatrixRow
        {...this.props}
        isRowDeleted={isRowDeleted}
        acceptAll={acceptAll}
        discardAll={discardAll}
      />
    );
  }
}

MatrixRowContainer.propTypes = {
  // Passed props
  baseAnswersName: PropTypes.string.isRequired,
  baseAnswersChangesName: PropTypes.string.isRequired,

  // Connected props
  rowDeletionChange: sowTypes.planAppChangeType,
};

MatrixRowContainer.defaultProps = {
  rowDeletionChange: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(MatrixRowContainer);
