import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { withRouter } from 'react-router';
import moment from 'moment';

import AcaOrgSearch from 'sow/components/organisms/AcaOrgSearch';
import * as currentUser from 'sow/selectors/currentUser';
import actions from 'sow/actions/pure';
import { fetchCertificationSpecialistList } from 'sow/actions/acaAdmin';
import { resourceListRead } from 'sow/store/helpers';
import { acaIdConstant } from 'sow/constants/acaId';
import { planAppStateText } from 'sow/constants/planApp';
import { API_URL } from 'sow/constants/api';

const dateType = {
  anniversaryDateStart: 'anniversaryDate',
  anniversaryDateEnd: 'anniversaryDate',
  inspectionDateStart: 'inspectionDate',
  inspectionDateEnd: 'inspectionDate',
};

const urlKeys = {
  orgName: 'a',
  ospStatus: 'b',
  anniversaryDateStart: 'c',
  anniversaryDateEnd: 'd',
  inspectorSelect: 'e',
  initialReviewerSelect: 'f',
  statusStartTime: 'g',
  statusEndTime: 'h',
  category: 'i',
  acaSelect: 'j',
  inspectionDateStart: 'k',
  inspectionDateEnd: 'l',
  productName: 'm',
  ingredientName: 'n',
  locationCount: 'o',
  locationState: 'p',
  hardCopy: 'q',
  newClient: 'r',
  status: 's',
  activeStatuses: 't',
  csAssigned: 'u',
  finalReviewerSelect: 'v',
  ospFlag: 'w',
  certifications: 'x',
  consultantCompanies: 'y',
  nopID: 'z',
};

const stateValues = {
  a: 'orgName',
  b: 'ospStatus',
  c: 'anniversaryDateStart',
  d: 'anniversaryDateEnd',
  e: 'inspectorSelect',
  f: 'initialReviewerSelect',
  g: 'statusStartTime',
  h: 'statusEndTime',
  i: 'category',
  j: 'acaSelect',
  k: 'inspectionDateStart',
  l: 'inspectionDateEnd',
  m: 'productName',
  n: 'ingredientName',
  o: 'locationCount',
  p: 'locationState',
  q: 'hardCopy',
  r: 'newClient',
  s: 'status',
  t: 'activeStatuses',
  u: 'csAssigned',
  v: 'finalReviewerSelect',
  w: 'ospFlag',
  x: 'certifications',
  y: 'consultantCompanies',
  z: 'nopID',
};

const inspectorListResource = resourceListRead('inspector', 'inspector');

const mapStateToProps = (state, props) => {
  const { selectors: inspectorListSelectors } = inspectorListResource;
  const inspectorListResult = inspectorListSelectors.result(state);

  return {
    // userId: currentUser.id(state, props),
    isCertSpecialist: currentUser.isCertificationSpecialist(state, props),
    admin: currentUser.isSuperAdmin(state, props),
    inspectorList: inspectorListResult,
    specialistList: state.acaAdminList.items,
    isFetchingSpecialist: state.acaAdminList.isFetching,
    acaList: state.acas.byId,
    isSearching: state.organizations.orgSearch.isSearching,
    searchInitiated: state.organizations.orgSearch.searchInitiated,
    searchResults: state.organizations.orgSearch.searchResults,
    savedSearch: state.organizations.orgSearch.savedSearch,
  };
};

const mapDispatchToProps = {
  fetchCertificationSpecialistList,
  loadInspectorList: inspectorListResource.action,
  orgSearch: actions.org.orgSearch,
  clearForm: actions.org.orgSearchClear,
  fetchSelectOptions: actions.org.orgSearchOptions,
};

const initialState = {
  allFilters: false,
  reload: false,
  urlRead: false,
  formValues: { acaSelect: acaIdConstant, activeStatuses: true },
  formErrors: {},
  dateErrors: {},
  countErrors: {},
};

class AcaOrgSearchContainer extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = initialState;
  }

  addOptionsToState({ data }) {
    if (this._isMounted) {
      this.setState({ selectOptions: data });
    }
  }

  componentDidMount() {
    this._isMounted = true;
    const {
      loadInspectorList,
      fetchCertificationSpecialistList,
      fetchSelectOptions,
      savedSearch,
    } = this.props;
    fetchSelectOptions(this.addOptionsToState.bind(this));
    loadInspectorList();
    fetchCertificationSpecialistList(acaIdConstant, { only_specialists: true });
    if (savedSearch && !R.isEmpty(savedSearch)) {
      const [searchValues, urlValues] = this.convertSearchValue(savedSearch.formValues);
      this.updateURL(urlValues);
      this.setState({
        formValues: savedSearch.formValues,
        allFilters: savedSearch.allFilters,
      });
    } else {
      const url = window.location.href.split('?');
      if (url[1]) {
        this.setState({ reload: true });
      }
    }
  }

  componentDidUpdate() {
    if (this.state.reload && this.state.selectOptions) {
      const url = window.location.href.split('?');
      this.urlSearch(url[1]);
    } else if (this.state.urlRead) {
      this.handleSearch();
      this.setState({ urlRead: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleFilterClick() {
    const { allFilters } = this.state;
    this.setState({ allFilters: !allFilters });
  }

  handleOptionChange(value, field) {
    const { formValues } = this.state;
    this.setState({ formValues: { ...formValues, [field]: value } });
  }

  handleInputChange({ target }) {
    const { id, value } = target;
    const { formValues, countErrors } = this.state;
    const regex = new RegExp('^[0-9]+$');
    if (
      (id === 'statusStartTime' || id === 'statusEndTime' || id === 'locationCount') &&
      value.match(regex) === null &&
      value !== ''
    ) {
      this.setState({
        countErrors: { ...countErrors, [id]: true },
        formValues: { ...formValues, [id]: value },
      });
    } else {
      this.setState({
        countErrors: { ...countErrors, [id]: false },
        formValues: { ...formValues, [id]: value },
      });
    }
  }

  handleSelectChange(target, field) {
    const { formValues } = this.state;
    this.setState({
      formValues: { ...formValues, [field]: target ? target.value : null },
    });
  }

  handleDateChange(value, field) {
    const { formValues, dateErrors } = this.state;

    if (typeof value === 'object') {
      this.setState({
        dateErrors: { ...dateErrors, [dateType[field]]: false },
        formValues: { ...formValues, [field]: value },
      });
    } else if (value === '') {
      this.setState({
        dateErrors: { ...dateErrors, [dateType[field]]: false },
        formValues: { ...formValues, [field]: value },
      });
    } else {
      this.setState({
        dateErrors: { ...dateErrors, [dateType[field]]: true },
        formValues: { ...formValues, [field]: value },
      });
    }
  }

  handleMultiSelectChange(field, target) {
    const { formValues } = this.state;
    this.setState({ formValues: { ...formValues, [field]: target } });
  }

  handleSearch() {
    const { formValues, dateErrors, countErrors, allFilters } = this.state;

    let formErrors = {};

    for (const property in dateErrors) {
      if (dateErrors[property]) {
        formErrors[property] = [
          'Date is invalid. Please use MM/DD/YYYY format ex. (04/05/2022)',
        ];
      }
    }

    for (const property in countErrors) {
      if (countErrors[property] && property.includes('status')) {
        formErrors['statusAge'] = ['Input is invalid. Please use only numbers.'];
      } else if (countErrors[property]) {
        formErrors[property] = ['Input is invalid. Please use only numbers.'];
      }
    }

    this.setState({ formErrors: formErrors });

    if (R.isEmpty(formErrors) && this.checkDateEntries()) {
      const [searchValues, urlValues] = this.convertSearchValue(formValues);
      this.updateURL(urlValues);
      this.props.orgSearch(searchValues, { formValues, allFilters });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  handleReset() {
    const { allFilters } = this.state;
    this.setState({
      allFilters: allFilters,
      formValues: { acaSelect: acaIdConstant, activeStatuses: true },
      formErrors: {},
      dateErrors: {},
      countErrors: {},
    });
    this.props.clearForm();
    history.pushState(null, null, `/aca/${acaIdConstant}/org`);
  }

  urlSearch(urlString) {
    if (this.props.specialistList.length > 0 && this.state.reload) {
      const urlFormValues = {};
      const urlParams = urlString.split('&');
      urlParams.forEach(param => {
        const keyValue = param.split('=');
        switch (keyValue[0]) {
          case 'b':
            let statusSelections = decodeURIComponent(keyValue[1]);
            statusSelections = statusSelections.split(',');
            urlFormValues[stateValues[keyValue[0]]] = [];
            statusSelections.forEach(status => {
              urlFormValues[stateValues[keyValue[0]]].push({
                value: status,
                label: planAppStateText[status] || 'Not Started',
              });
            });
            break;
          case 'q':
          case 'r':
          case 's':
          case 't':
          case 'u': {
            urlFormValues[stateValues[keyValue[0]]] = keyValue[1] === 'true';
            break;
          }
          case 'i':
            let urlCategories = decodeURIComponent(keyValue[1]);
            urlCategories = urlCategories.split(',');
            urlFormValues[stateValues[keyValue[0]]] = [];
            const categoryValues = {};
            this.state.selectOptions.organization_type_options.forEach(
              organization_type =>
                (categoryValues[organization_type.value] = {
                  value: organization_type.value,
                  label: organization_type.label,
                }),
            );
            urlCategories.forEach(categoryId => {
              if (categoryValues[categoryId]) {
                urlFormValues[stateValues[keyValue[0]]].push(categoryValues[categoryId]);
              }
            });
            break;
          case 'f':
          case 'v':
            let urlSpecialists = decodeURIComponent(keyValue[1]);
            urlSpecialists = urlSpecialists.split(',');
            urlFormValues[stateValues[keyValue[0]]] = [];
            const specialistValues = {};
            this.props.specialistList.forEach(
              specialist =>
                (specialistValues[specialist.id] = {
                  value: specialist.id,
                  label: specialist.name,
                }),
            );
            urlSpecialists.forEach(specialistId => {
              urlFormValues[stateValues[keyValue[0]]].push(
                specialistValues[specialistId],
              );
            });
            break;
          case 'c':
          case 'd':
          case 'k':
          case 'l':
            urlFormValues[stateValues[keyValue[0]]] = moment(keyValue[1]);
            break;
          case 'w':
            let urlOSPTags = decodeURIComponent(keyValue[1]);
            urlOSPTags = urlOSPTags.split(',');
            urlFormValues[stateValues[keyValue[0]]] = [];
            const ospTagValues = {};
            this.state.selectOptions.osp_tag_options.forEach(
              ospTag =>
                (ospTagValues[ospTag.value] = {
                  value: ospTag.value,
                  label: ospTag.label,
                }),
            );
            urlOSPTags.forEach(categoryId => {
              if (ospTagValues[categoryId]) {
                urlFormValues[stateValues[keyValue[0]]].push(ospTagValues[categoryId]);
              }
            });
            break;
          case 'x':
            let urlCertifications = decodeURIComponent(keyValue[1]);
            urlCertifications = urlCertifications.split(',');
            urlFormValues[stateValues[keyValue[0]]] = [];
            const certificationValues = {};
            this.state.selectOptions.certification_options.forEach(
              certification =>
                (certificationValues[certification.value] = {
                  value: certification.value,
                  label: certification.label,
                }),
            );
            urlCertifications.forEach(certificationId => {
              if (certificationValues[certificationId]) {
                urlFormValues[stateValues[keyValue[0]]].push(
                  certificationValues[certificationId],
                );
              }
            });
            break;
          case 'y':
            let urlConsultantCompanies = decodeURIComponent(keyValue[1]);
            urlConsultantCompanies = urlConsultantCompanies.split(',');
            urlFormValues[stateValues[keyValue[0]]] = [];
            const consultantCompaniesValues = {};
            this.state.selectOptions.consultant_options.forEach(
              consultant =>
                (consultantCompaniesValues[consultant.value] = {
                  value: consultant.value,
                  label: consultant.label,
                }),
            );
            urlConsultantCompanies.forEach(consultantId => {
              if (consultantCompaniesValues[consultantId]) {
                urlFormValues[stateValues[keyValue[0]]].push(
                  consultantCompaniesValues[consultantId],
                );
              }
            });
            break;
          default:
            urlFormValues[stateValues[keyValue[0]]] = decodeURIComponent(keyValue[1]);
            break;
        }
      });
      this.setState({
        formValues: { ...urlFormValues, acaSelect: acaIdConstant },
        reload: false,
        allFilters: true,
        urlRead: true,
      });
    }
  }

  updateURL(formValues) {
    let urlString = '?';
    const urlParams = [];
    for (const property in formValues) {
      if (
        !R.isEmpty(formValues[property]) &&
        urlKeys[property] !== undefined &&
        formValues[property] !== undefined &&
        formValues[property] !== null
      ) {
        urlParams.push(
          `${urlKeys[property]}=${encodeURIComponent(formValues[property])}`,
        );
      }
    }

    urlParams.map((param, index) => {
      if (index === 0) {
        urlString += param;
        return;
      }
      urlString += '&' + param;
    });
    history.pushState(null, null, urlString);
  }

  checkDateEntries() {
    const { formValues } = this.state;
    let formErrors = {};
    for (const property in formValues) {
      if (
        property === 'anniversaryDateStart' &&
        formValues[property] > formValues.anniversaryDateEnd &&
        formValues[property] != '' &&
        formValues.anniversaryDateEnd != ''
      ) {
        formErrors['anniversaryDate'] = [
          'Invalid entry. Start date must be before end date.',
        ];
      } else if (
        property === 'statusStartTime' &&
        Number(formValues.statusStartTime) > Number(formValues.statusEndTime) &&
        formValues[property] !== '' &&
        formValues.statusEndTime !== ''
      ) {
        formErrors['statusAge'] = [
          'Invalid entry. Starting age must be before ending age.',
        ];
      } else if (
        property === 'inspectionDateStart' &&
        formValues[property] > formValues.inspectionDateEnd &&
        formValues[property] !== '' &&
        formValues.inspectionDateEnd !== ''
      ) {
        formErrors['inspectionDate'] = [
          'Invalid entry. Start date must be before end date.',
        ];
      }
    }

    if (R.isEmpty(formErrors)) {
      return true;
    }

    this.setState({ formErrors: { ...formErrors } });
  }

  convertSearchValue(formValues) {
    const urlValues = {};
    const searchValues = {};

    for (const property in formValues) {
      if (!R.isEmpty(formValues[property])) {
        switch (property) {
          case 'orgName':
          case 'productName':
          case 'locationState':
            searchValues[property] = formValues[property];
            urlValues[property] = formValues[property];
            break;
          case 'ospStatus':
            const ospStatusSelections = [];
            const ospStatusURL = [];

            formValues.ospStatus.forEach(status => {
              ospStatusSelections.push(`${status.value}`);
              ospStatusURL.push(status.value);
            });
            ospStatusSelections.sort();
            searchValues[property] = ospStatusSelections.join(',');
            urlValues[property] = ospStatusURL.join(',');
            break;
          case 'inspectorSelect':
            if (formValues[property]) {
              searchValues[property] = formValues[property];
              urlValues[property] = formValues[property];
            }
            break;
          case 'anniversaryDateStart':
          case 'anniversaryDateEnd':
          case 'inspectionDateStart':
          case 'inspectionDateEnd':
            searchValues[property] = formValues[property].format('YYYY-MM-DD');
            urlValues[property] = searchValues[property];
            break;
          case 'statusStartTime':
            const statusStartDate = moment
              .utc()
              .subtract(formValues[property], 'd')
              .format('YYYY-MM-DD HH:mm:ss');
            searchValues[property] = statusStartDate;
            urlValues[property] = formValues[property];
            break;
          case 'statusEndTime':
            const statusDate = moment
              .utc()
              .subtract(formValues[property] * 24 + 24, 'hours')
              .format('YYYY-MM-DD HH:mm:ss');
            searchValues[property] = statusDate;
            urlValues[property] = formValues[property];
            break;
          case 'category':
            const categoryFilter = [];
            formValues.category.forEach(category => {
              categoryFilter.push(String(category.value));
            });
            searchValues[property] = categoryFilter.join(',');
            urlValues[property] = categoryFilter.join(',');
            break;
          case 'initialReviewerSelect':
            const initialReviewerSelections = [];
            formValues.initialReviewerSelect.forEach(specialist =>
              initialReviewerSelections.push(specialist.value),
            );
            initialReviewerSelections.sort();
            searchValues['initialReviewerIds'] = initialReviewerSelections.join(',');
            urlValues['initialReviewerSelect'] = initialReviewerSelections.join(',');
            break;
          case 'finalReviewerSelect':
            const finalReviewerSelections = [];

            formValues.finalReviewerSelect.forEach(specialist =>
              finalReviewerSelections.push(specialist.value),
            );
            finalReviewerSelections.sort();
            searchValues['finalReviewerIds'] = finalReviewerSelections.join(',');
            urlValues['finalReviewerSelect'] = finalReviewerSelections.join(',');
            break;
          case 'ospFlag':
            const ospTagSelections = [];
            formValues[property].forEach(certification => {
              ospTagSelections.push(certification.value);
            });
            searchValues[property] = ospTagSelections.join(',');
            urlValues[property] = ospTagSelections.join(',');
            break;
          case 'certifications':
            const certificationSelections = [];
            formValues[property].forEach(certification => {
              certificationSelections.push(certification.value);
            });
            searchValues[property] = certificationSelections.join(',');
            urlValues[property] = certificationSelections.join(',');
            break;
          case 'consultantCompanies':
            const consultantCompaniesSelections = [];
            formValues[property].forEach(certification => {
              consultantCompaniesSelections.push(certification.value);
            });
            searchValues[property] = consultantCompaniesSelections.join(',');
            urlValues[property] = consultantCompaniesSelections.join(',');
            break;
          default:
            searchValues[property] = formValues[property];
            urlValues[property] = formValues[property];
            break;
        }
      }
    }
    // Restrict access to orgs assigned to CS'
    // if (this.props.isCertSpecialist && !this.props.admin) {
    //   urlValues['specialistSelect'] = null;
    //   searchValues['specialist_ids'] = null;
    //   searchValues['user_id'] = this.props.userId;
    // }
    return [searchValues, urlValues];
  }

  exportList() {
    const { formValues } = this.state;
    const [searchValues] = this.convertSearchValue(formValues);
    const params = [];
    for (let key in searchValues) {
      params.push(`${key}=${searchValues[key]}`);
    }
    let paramsString = params.join('&');
    paramsString = '?'.concat(paramsString);
    window.open(`${API_URL}/v1/org/org_search/export${paramsString}`, '_blank');
  }

  render() {
    return (
      <AcaOrgSearch
        handleFilterClick={this.handleFilterClick.bind(this)}
        handleOptionChange={this.handleOptionChange.bind(this)}
        handleInputChange={this.handleInputChange.bind(this)}
        handleReset={this.handleReset.bind(this)}
        handleSelectChange={this.handleSelectChange.bind(this)}
        handleDateChange={this.handleDateChange.bind(this)}
        handleMultiSelectChange={this.handleMultiSelectChange.bind(this)}
        exportList={this.exportList.bind(this)}
        handleSearch={this.handleSearch.bind(this)}
        inspectorList={this.props.inspectorList}
        specialistList={this.props.specialistList}
        handleSubmit={this.handleSubmit.bind(this)}
        acaList={this.props.acaList}
        state={this.state}
        isCertSpecialist={this.props.isCertSpecialist}
        isAdmin={this.props.admin}
        hasResults={this.props.searchResults.length > 0}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AcaOrgSearchContainer);
