import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import PriorityDesignationTypeEditContainer from 'sow/containers/PriorityDesignationTypeEditContainer';

const PriorityDesignationTypeEditPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <PriorityDesignationTypeEditContainer />}
  </AdminPageTemplate>
);

export default PriorityDesignationTypeEditPage;
