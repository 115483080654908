import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as adminRequestActions from 'sow/actions/adminRequest';
import * as currentUser from 'sow/selectors/currentUser';
import * as sowTypes from 'sow/types';
import { resourceUpdate } from 'sow/store/helpers';
import { fromPlanApp } from 'sow/store/selectors';
import DeleteRowButton from 'sow/components/organisms/WorksheetMatrix/DeleteRowButton';
import DeletionChange from 'sow/components/organisms/DeletionChange';

const getCreateChangeResource = (orgId, planAppId, changeRequestId) =>
  resourceUpdate(
    `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`,
    'changeRequestOverviewResp',
  );

const mapStateToProps = (state, props) => {
  const { orgId, planAppId, changeRequestId } = props;
  const { selectors } = getCreateChangeResource(orgId, planAppId, changeRequestId);

  return {
    userId: currentUser.id(state, props),
    isNewRow: fromPlanApp.matrixRowIsNewRow(state, props),
    rowDeletionChange: fromPlanApp.matrixRowDeletionChange(state, props),
    isCreatingChange: selectors.pending(state, props),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    orgId,
    planAppId,
    changeRequestId,
    worksheetId,
    matrixRowId,
    locationId,
  } = ownProps;
  const { action } = getCreateChangeResource(orgId, planAppId, changeRequestId);

  return {
    deleteRow: () =>
      dispatch(
        action(null, {
          changes: [
            {
              applicationId: planAppId,
              changeRequestId: changeRequestId,
              worksheetId: worksheetId,
              matrixRowId: matrixRowId,
              landId: locationId,
              context: R.isNil(locationId) ? 'main' : 'land',
              type: 'matrix_row',
              action: 'deleted',
            },
          ],
        }),
      ),
    refreshAdminRequests: () =>
      adminRequestActions.refreshAdminRequests(orgId, planAppId, locationId)(dispatch),
  };
};

const MatrixRowDeletionChangeContainer = ({
  rowDeletionChange,
  isCreatingChange,
  deleteRow,
  changeUIDisabled,
  refreshAdminRequests,
}) => {
  if (rowDeletionChange)
    return (
      <DeletionChange
        change={rowDeletionChange}
        changeUIDisabled={changeUIDisabled}
        changeText="This row will be deleted from the plan."
      />
    );

  return (
    <DeleteRowButton
      onDelete={() => deleteRow().then(() => refreshAdminRequests())}
      disabled={isCreatingChange || changeUIDisabled}
    />
  );
};

MatrixRowDeletionChangeContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  changeRequestId: sowTypes.planAppChangeRequestIdType.isRequired,
  worksheetId: sowTypes.worksheetIdType.isRequired,
  locationId: sowTypes.planAppLocationIdType,
  matrixRowId: sowTypes.planAppWorksheetQuestionMatrixRowIdType.isRequired,
  changeUIDisabled: PropTypes.bool.isRequired,

  rowDeletionChange: sowTypes.planAppChangeType,
  isCreatingChange: PropTypes.bool.isRequired,
  deleteRow: PropTypes.func.isRequired,
  acceptChange: PropTypes.func,
};

MatrixRowDeletionChangeContainer.defaultProps = {
  rowDeletionChange: null,
  locationId: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(MatrixRowDeletionChangeContainer);
