import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Atom from 'sow/components/atoms/Atom';
import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import Nav from 'sow/components/atoms/Nav';
import AcaLogo from 'sow/components/molecules/AcaLogo';
import SidebarAdmin from 'sow/components/organisms/SidebarAdmin';
import SidebarAcaAdmin from 'sow/containers/SidebarAcaAdmin';
import SidebarInspector from 'sow/components/organisms/SidebarInspector';
import SidebarOrg from 'sow/components/organisms/SidebarOrg';
import SidebarUnaffiliated from 'sow/components/organisms/SidebarUnaffiliated';
import SidebarClient from 'sow/components/organisms/SidebarClient';
import SidebarTodo from 'sow/components/organisms/SidebarTodo';
import SidebarResources from 'sow/components/organisms/SidebarResources';

/* Note: sidebar is based on this link: */
/* http://bootsnipp.com/snippets/featured/admin-menu */

const sidebarStyles = {
  header: {
    logoContainer: {
      width: '100%',
      textAlign: 'center',
    },
    logo: {
      width: '65%',
      height: 'auto',
    },
    logoSm: {
      float: 'right',
      width: '25px',
      height: 'auto',
      marginTop: '-5px',
      marginLeft: '10px',
    },
    linkStyle: {
      paddingBottom: '160px',
      width: '100%',
      backgroundColor: '#2a1b0c',
    },
  },
  footer: {
    dataSecurityStyle: {
      width: '10%',
      marginRight: '10px',
    },
  },
};

const Sidebar = ({
  aca,
  org,
  activeOrgs,
  showAcaUI,
  showAdminUI,
  showOrgUI,
  showInspectorUI,
  showClientUI,
  isCcof,
  navbarToggled,
  onNavbarToggle,
}) => {
  const showAdminSidebar = showAdminUI;
  const showAcaSidebar = showAcaUI;
  const showOrgSidebar = showOrgUI && org;
  const showInspectorSidebar = showInspectorUI;
  const showClientSidebar =
    showClientUI &&
    !showInspectorUI &&
    !showAdminUI &&
    !showAcaUI &&
    activeOrgs.length > 1;
  // const showUnaffiliatedSidebar = showOrgSidebar && !showInspectorUI;

  const navClasses = classnames('nav', 'navbar-nav', navbarToggled ? 'slide-in' : '');

  const absoluteWrapperClasses = classnames(
    'absolute-wrapper',
    'hidden-xs',
    navbarToggled ? 'slide-in' : '',
  );

  return (
    <Block className={isCcof ? 'sidebar-ccof' : 'sidebar-normal'}>
      <Block className={absoluteWrapperClasses} />

      <Block className="side-menu">
        <Nav className="navbar navbar-default">
          {/* Brand and toggle get grouped for better mobile display */}
          <Block className="navbar-header">
            <Block className="brand-wrapper">
              {/* Hamburger */}
              <Button type="button" className="navbar-toggle" onClick={onNavbarToggle}>
                <Atom className="sr-only">Toggle navigation</Atom>
                <Atom className="icon-bar" />
                <Atom className="icon-bar" />
                <Atom className="icon-bar" />
              </Button>

              {/* Brand */}
              <Block className="brand-name-wrapper">
                <Link
                  className="navbar-brand hidden-xs"
                  style={sidebarStyles.header.linkStyle}
                  to="/"
                >
                  <Block style={sidebarStyles.header.logoContainer}>
                    <img
                      src="/assets/images/WFCFO-logo.png"
                      style={sidebarStyles.header.logo}
                    />
                  </Block>
                </Link>
                <Link to="/" className="navbar-brand visible-xs">
                  <Block style={sidebarStyles.header.logoContainer}>
                    <img
                      src="/assets/images/WFCFO-logo.png"
                      style={sidebarStyles.header.logoSm}
                    />{' '}
                    WFCF Organic
                  </Block>
                </Link>
              </Block>
            </Block>
          </Block>

          <Block className="side-menu-container">
            <Block className="aca-logo" style={{ width: '100%' }}>
              <AcaLogo
                aca={aca}
                bustCache={false}
                className="aca-logo img-responsive hidden-xs center-block"
              />
            </Block>
            {showAdminSidebar && <SidebarAdmin navClasses={navClasses} />}
            {showAcaSidebar && <SidebarAcaAdmin org={org} navClasses={navClasses} />}
            {showInspectorSidebar && <SidebarInspector navClasses={navClasses} />}
            {showClientSidebar && <SidebarClient navClasses={navClasses} />}
            {showOrgSidebar && <SidebarOrg org={org} navClasses={navClasses} />}
            {showClientSidebar && <SidebarTodo navClasses={navClasses} />}
            <SidebarResources navClasses={navClasses} />
            {/* {showUnaffiliatedSidebar && <SidebarUnaffiliated navClasses={navClasses} />} */}
            <Block className="side-menu-footer">
              <Block className={classnames('security-wrapper', navClasses)}>
                <img
                  src="/assets/images/data-security.png"
                  style={sidebarStyles.footer.dataSecurityStyle}
                />{' '}
                <Block>
                  COMMITMENT TO DATA
                  <br />
                  SECURITY AND PRIVACY
                </Block>
              </Block>
            </Block>
          </Block>
        </Nav>
      </Block>
    </Block>
  );
};

Sidebar.propTypes = {
  aca: PropTypes.object,
  org: PropTypes.object,
  showAdminUI: PropTypes.bool,
  showOrgUI: PropTypes.bool,
  showAcaUI: PropTypes.bool,
  showInspectorUI: PropTypes.bool,
  isCcof: PropTypes.bool,
  navbarToggled: PropTypes.bool,
  onNavbarToggle: PropTypes.func,
};

export default Sidebar;
