import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { updateURL, urlSearchValues } from 'sow/utils/searchURLUpdate';
import * as consultantCompanyActions from 'sow/actions/pure/consultantCompany';
import ConsultantCompaniesList from 'sow/components/organisms/ConsultantCompaniesList';

const mapDispatchToProps = {
  loadConsultantCompanies: consultantCompanyActions.loadConsultantCompanies,
};

const initialState = { loadingList: true, formValues: {} };

class ConsultantCompaniesListContainer extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = initialState;
  }

  updateState({ data }) {
    if (this._isMounted) {
      this.setState({ loadingList: false, consultantList: data });
    }
  }

  loadConsultantCompanies(params) {
    this.props.loadConsultantCompanies(params, this.updateState.bind(this));
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.location.search) {
      const searchValues = urlSearchValues(this.props.location.search.slice(1));
      this.setState({ formValues: searchValues });
      this.loadConsultantCompanies(searchValues);
    } else {
      this.loadConsultantCompanies(null);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInputChange({ target }) {
    const { id, value } = target;
    const { formValues } = this.state;
    this.setState({ formValues: { ...formValues, [id]: value } });
  }

  handleSearch(event) {
    event.preventDefault();
    updateURL(this.state.formValues);
    this.loadConsultantCompanies({ name: this.state.formValues.name });
  }

  handleReset() {
    this.setState(initialState);
    this.loadConsultantCompanies(null);
    history.pushState(null, null, '/admin/system-admin/object/consultant-companies');
  }

  render() {
    return (
      <ConsultantCompaniesList
        loadingList={this.state.loadingList}
        formValues={this.state.formValues}
        consultantList={this.state.consultantList}
        handleInputChange={this.handleInputChange.bind(this)}
        handleSearch={this.handleSearch.bind(this)}
        handleReset={this.handleReset.bind(this)}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(ConsultantCompaniesListContainer);
