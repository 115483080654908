import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import * as announcementActions from 'sow/actions/pure/announcement';
import * as announcementTypeActions from 'sow/actions/pure/announcementType';

import AnnouncementsList from 'sow/components/organisms/AnnouncementsList';

const cleanFilters = filters => {
  let cleanedFilters = {};
  for (let key in filters) {
    switch (R.type(filters[key])) {
      case 'String':
      case 'Boolean':
      case 'Number':
        if (R.isEmpty(filters[key])) {
          break;
        } else {
          cleanedFilters[key] = filters[key];
          break;
        }

      default:
        break;
    }
  }
  return cleanedFilters;
};

const mapDispatchToProps = {
  loadAnnouncements: announcementActions.loadAnnouncements,
  loadAnnouncementTypeOptions: announcementTypeActions.loadAnnouncementTypes,
};

const initialState = { loadingList: true, initialValues: {} };

class AnnouncementsListContainer extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = R.clone(initialState);
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.loadAnnouncementTypeOptions(
      { enabled: true, options: true },
      this.storeOptionsAndFetchList.bind(this),
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState({ data }) {
    if (this._isMounted) {
      this.setState({
        loadingList: false,
        announcementList: data.announcements,
      });
    }
  }

  storeOptionsAndFetchList({ data }) {
    if (this._isMounted) {
      this.setState({ announcementTypes: data.announcement_types });
      if (this.props.location.search) {
        const searchValues = queryString.parse(this.props.location.search);
        this.loadAnnouncements(searchValues);
        this.setState({ initialValues: { ...searchValues } });
      } else {
        this.loadAnnouncements(null);
      }
    }
  }

  loadAnnouncements(params) {
    this.props.loadAnnouncements(params, this.updateState.bind(this));
  }

  handleSearch(values, formikBag) {
    this.setState({ loadingList: true });
    const cleanedFilters = cleanFilters(values);
    const urlParams = queryString.stringify(cleanedFilters);

    if (urlParams) {
      history.pushState(null, null, `?${urlParams}`);
    } else {
      history.pushState(null, null, '/admin/system-admin/object/announcements');
    }
    this.loadAnnouncements(cleanedFilters);
  }

  resetSearch(values, formikBag) {
    this.setState(initialState);
    formikBag.resetForm({ ...initialState.initialValues });
    this.loadAnnouncements(null);
    history.pushState(null, null, '/admin/system-admin/object/announcements');
  }

  render() {
    return (
      <AnnouncementsList
        announcementList={this.state.announcementList}
        handleSearch={this.handleSearch.bind(this)}
        resetSearch={this.resetSearch.bind(this)}
        {...this.state}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(AnnouncementsListContainer);
