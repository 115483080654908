import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { isStateComplete } from 'sow/utils/planAppState';
import * as sowTypes from 'sow/types';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Button from 'sow/components/atoms/Button';
import InformationIcon from 'sow/components/molecules/InformationIcon';

const OrgDashboardAppFields = ({
  underInvestigation,
  inMediation,
  noncompliant,
  showBroilerOlps,
  showPulletLayerOlps,
  showOtherOlps,
  toggleTag,
  expedited,
  tagChangePending,
  planApp,
  isLivestockOrg,
}) => (
  <Fragment>
    <Button
      name="noncompliant"
      className="org-sidebar-button"
      onClick={toggleTag}
      disabled={tagChangePending}
    >
      <FontAwesome className="sidebar-button-icon" icon="xmark-large" />
      {noncompliant ? 'Remove Plan Noncompliant' : 'Mark Plan Noncompliant'}
      <InformationIcon className="sidebar-button-info" overlay="test" />
    </Button>
    <HorizontalRule className="sidebar-button-divider" />
    {!isStateComplete(planApp) && (
      <Fragment>
        <Button
          name="is_expedited"
          className="org-sidebar-button"
          onClick={toggleTag}
          disabled={tagChangePending}
        >
          <FontAwesome className="sidebar-button-icon" icon="stopwatch" />
          {expedited ? 'Remove Expedited Flag' : 'Flag Plan as Expedited'}
          <InformationIcon className="sidebar-button-info" overlay="test" />
        </Button>
        <HorizontalRule className="sidebar-button-divider" />
      </Fragment>
    )}
    <Button
      name="is_under_investigation"
      className="org-sidebar-button"
      onClick={toggleTag}
      disabled={tagChangePending}
    >
      <FontAwesome className="sidebar-button-icon" icon="eye" variant="far" />
      {underInvestigation ? 'Investigation Complete' : 'Under Investigation'}
      <InformationIcon className="sidebar-button-info" overlay="test" />
    </Button>
    <HorizontalRule className="sidebar-button-divider" />
    <Button
      name="is_in_mediation"
      className="org-sidebar-button"
      onClick={toggleTag}
      disabled={tagChangePending}
    >
      <FontAwesome className="sidebar-button-icon" icon="handshake" variant="far" />
      {inMediation ? 'Remove Settlement Agreement' : 'Settlement Agreement'}
      <InformationIcon className="sidebar-button-info" overlay="test" />
    </Button>
    {isLivestockOrg && (
      <Fragment>
        <HorizontalRule className="sidebar-button-divider" />
        <Button
          name="broiler_olps"
          className="org-sidebar-button"
          onClick={toggleTag}
          disabled={tagChangePending}
        >
          <FontAwesome
            className="sidebar-button-icon"
            icon="circle-up-right"
            variant="far"
          />
          {showBroilerOlps ? 'Remove Broiler OLPS Extension' : 'Broiler OLPS Extension'}
          <InformationIcon className="sidebar-button-info" overlay="test" />
        </Button>
        <HorizontalRule className="sidebar-button-divider" />
        <Button
          name="pullet_layer_olps"
          className="org-sidebar-button"
          onClick={toggleTag}
          disabled={tagChangePending}
        >
          <FontAwesome
            className="sidebar-button-icon"
            icon="up-right-and-down-left-from-center"
          />
          {showPulletLayerOlps
            ? 'Remove Pullet/Layer OLPS Extension'
            : 'Pullet/Layer OLPS Extension'}
          <InformationIcon className="sidebar-button-info" overlay="test" />
        </Button>
        <HorizontalRule className="sidebar-button-divider" />
        <Button
          name="other_poultry_olps"
          className="org-sidebar-button"
          onClick={toggleTag}
          disabled={tagChangePending}
        >
          <FontAwesome className="sidebar-button-icon" icon="arrows-maximize" />
          {showOtherOlps
            ? 'Remove Other Poultry OLPS Extension'
            : 'Other Poultry OLPS Extension'}
          <InformationIcon className="sidebar-button-info" overlay="test" />
        </Button>
      </Fragment>
    )}
  </Fragment>
);

OrgDashboardAppFields.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  planApp: sowTypes.planAppType.isRequired,
  isWorkflowManager: PropTypes.bool,
  inMediation: PropTypes.bool,
  underInvestigation: PropTypes.bool,
  markExpedited: PropTypes.func,
  markUnderInvestigation: PropTypes.func,
  markInMediation: PropTypes.func,
  expedited: PropTypes.bool,
  noncompliant: PropTypes.bool,
  markNoncompliant: PropTypes.func,
};

export default OrgDashboardAppFields;
