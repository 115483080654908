import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as certificationTypeActions from 'sow/actions/pure/certificationType';
import CertificationTypesList from 'sow/components/organisms/CertificationTypesList';

const mapStateToProps = state => ({
  certificationList: state.certificationType.list,
  loadingList: state.certificationType.loadingList,
});

const mapDispatchToProps = {
  loadList: certificationTypeActions.loadAllCertificationTypes,
  updateSortOrder: certificationTypeActions.updateSortOrder,
};

const initialState = {
  formValues: {},
  listFiltered: false,
};

const updateURL = searchValues => {
  const urlParams = [];
  for (let key in searchValues) {
    urlParams.push(`${key}=${encodeURIComponent(searchValues[key])}`);
  }
  const urlString = `?${urlParams.join('&')}`;
  history.pushState(null, null, urlString);
};

const urlSearchValues = urlParams => {
  const searchValues = {};
  urlParams.split('&').forEach(param => {
    const paramKeyValue = param.split('=');
    searchValues[paramKeyValue[0]] = paramKeyValue[1];
  });
  return searchValues;
};

class CertificationTypesListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount() {
    if (this.props.location.search) {
      const searchValues = urlSearchValues(this.props.location.search.slice(1));
      this.props.loadList(searchValues);
      this.updateState(searchValues);
    } else {
      this.props.loadList();
    }
  }

  updateState(urlValues) {
    const formValues = {};
    for (const key in urlValues) {
      switch (key) {
        case 'name':
          formValues.certificationName = decodeURIComponent(urlValues[key]);
          break;
        case 'enabled':
          const selectValues = {
            true: { value: true, label: 'Enabled' },
            false: { value: false, label: 'Disabled' },
          };
          formValues.status = selectValues[decodeURIComponent(urlValues[key])];
          break;
        default:
          break;
      }
    }
    this.setState({ formValues });
  }

  handleInputChange({ target }) {
    const { formValues } = this.state;
    this.setState({ formValues: { ...formValues, [target.id]: target.value } });
  }

  handleSelectChange(id, value) {
    const { formValues } = this.state;
    this.setState({ formValues: { ...formValues, [id]: value } });
  }

  handleSearch(event) {
    event.preventDefault();
    const { formValues } = this.state;

    const searchValues = {};
    if (formValues.status) {
      searchValues.enabled = formValues.status.value;
    }
    if (
      !R.isNil(formValues.certificationName) &&
      !R.isEmpty(formValues.certificationName)
    ) {
      searchValues.name = formValues.certificationName;
    }

    if (!R.isEmpty(searchValues)) {
      this.props.loadList(searchValues);
      updateURL(searchValues);
    } else {
      this.props.loadList();
      history.pushState(null, null, '/admin/system-admin/object/certification-types');
    }
    this.setState({ listFiltered: true });
  }

  handleReset() {
    this.setState(initialState);
    this.props.loadList();
    history.pushState(null, null, '/admin/system-admin/object/certification-types');
  }

  updateSortOrder(updateList) {
    this.props.updateSortOrder(updateList);
  }

  render() {
    return (
      <CertificationTypesList
        formValues={this.state.formValues}
        listFiltered={this.state.listFiltered}
        certificationList={this.props.certificationList}
        handleInputChange={this.handleInputChange.bind(this)}
        handleSelectChange={this.handleSelectChange.bind(this)}
        handleSearch={this.handleSearch.bind(this)}
        handleReset={this.handleReset.bind(this)}
        updateSortOrder={this.updateSortOrder.bind(this)}
        loadingList={this.props.loadingList}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(CertificationTypesListContainer);
