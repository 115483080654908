import React from 'react';
import styled from 'styled-components';

import Block from 'sow/components/atoms/Block';
import FontAwesome from 'sow/components/atoms/FontAwesome';

const PillWrapper = styled(Block)`
  display: flex;
  align-items: baseline;
  background-color: ${({ primaryColor }) => primaryColor};
  color: ${({ secondaryColor }) => secondaryColor};
  height: 20px;
  border-radius: 10px;
  padding: 3px 5px 3px 3px;
`;

const MulticolorPill = ({ pillData }) => (
  <PillWrapper
    primaryColor={pillData.primary_color}
    secondaryColor={pillData.secondary_color}
  >
    <FontAwesome style={{ marginRight: '2px' }} icon="circle" />
    {pillData.name}
  </PillWrapper>
);

export default MulticolorPill;
