import * as R from 'ramda';

import { createSafeCachedSelector } from 'sow/utils/selectors';
import { isDeployProd } from 'sow/config';
import * as orgSelect from 'sow/selectors/core/organization';
import * as ospAppSelect from 'sow/selectors/core/ospApplication';

// Org
export const org = createSafeCachedSelector(
  [orgSelect.currentOrg],
  R.identity,
)(orgSelect.currentOrgId);

export const orgId = createSafeCachedSelector(
  [orgSelect.currentOrgId],
  R.identity,
)(orgSelect.currentOrgId);

export const acaId = createSafeCachedSelector(
  [orgSelect.currentOrgAcaId],
  R.identity,
)(orgId);

export const fetchingLocations = createSafeCachedSelector(
  [orgSelect.orgLocations],
  R.prop('fetchingLocations'),
)(orgId);

export const orgLocations = createSafeCachedSelector(
  [orgSelect.orgLocations],
  R.prop('locationList'),
)(orgId);

export const isLoading = createSafeCachedSelector(
  [orgSelect.isLoadingCurrentOrg],
  R.identity,
)(orgId);

export const isLoadFail = createSafeCachedSelector(
  [orgSelect.isLoadFailedCurrentOrg],
  R.identity,
)(orgId);

export const isLoaded = createSafeCachedSelector(
  [orgSelect.isLoadedCurrentOrg],
  R.identity,
)(orgId);

export const shouldLoad = createSafeCachedSelector(
  [orgSelect.shouldLoadCurrentOrg],
  R.identity,
)(orgId);

export const deactivated = createSafeCachedSelector([org], org => {
  return false === R.path(['active'], org);
})(orgId);

export const name = createSafeCachedSelector([org], R.path(['name']))(orgId);

export const settings = createSafeCachedSelector([org], R.propOr({}, 'settings'))(orgId);

export const orgSettings = createSafeCachedSelector(
  [settings, acaId],
  (orgSettings, acaId) => R.pathOr(null, ['aca_byid', acaId])(orgSettings),
)(orgId);

export const anniversaryDate = createSafeCachedSelector(
  [orgSettings],
  R.propOr(null, 'anniversary_date'),
)(orgId);

export const allLandScopes = createSafeCachedSelector(
  [orgSelect.currentOrgAllLandScopes],
  R.identity,
)(orgId);

export const facilityScopes = createSafeCachedSelector(
  [orgSelect.currentOrgFacilityScopes],
  R.identity,
)(orgId);

export const officeScopes = createSafeCachedSelector(
  [orgSelect.currentOrgOfficeScopes],
  R.identity,
)(orgId);

export const parcelScopes = createSafeCachedSelector(
  [orgSelect.currentOrgParcelScopes],
  R.identity,
)(orgId);

export const pastureScopes = createSafeCachedSelector(
  [orgSelect.currentOrgAllLandScopes],
  R.identity,
)(orgId);

export const productScopes = createSafeCachedSelector(
  // TODO update this to use actual config list
  [orgSelect.currentOrgAllLandScopes],
  R.identity,
)(orgId);

export const visibleTypes = createSafeCachedSelector(
  [orgSelect.currentOrgVisibleTypes],
  R.identity,
)(orgId);

// ospAppList
export const ospAppList = createSafeCachedSelector(
  [ospAppSelect.ospAppListForCurrentOrg],
  R.identity,
)(orgId);

export const currentAppId = createSafeCachedSelector(
  [orgSelect.currentAppId],
  R.identity,
)(orgId);

export const ospAppListIsLoaded = createSafeCachedSelector(
  [ospAppSelect.isLoadedForCurrentOrg],
  R.identity,
)(orgId);

export const ospAppListIsLoading = createSafeCachedSelector(
  [ospAppSelect.isLoadingForCurrentOrg],
  R.identity,
)(orgId);

export const ospAppListShouldLoad = createSafeCachedSelector(
  [ospAppListIsLoaded, ospAppListIsLoading], //, isLoadFailedCurrentOrg],
  (loaded, loading) => {
    //, failed) => {
    return !loaded && !loading;
  },
)(orgId);

export const hasOspApp = createSafeCachedSelector(
  [ospAppList],
  R.complement(R.isEmpty),
)(orgId);

export const configPlanEditLocationByOrgAllowed = createSafeCachedSelector(
  [orgSelect.currentOrgAcaConfigPlanEditLocationByOrgAllowed],
  R.identity,
)(orgId);

export const configPlanMinNumLocations = createSafeCachedSelector(
  [orgSelect.currentOrgAcaConfigPlanMinNumLocations],
  R.identity,
)(orgId);

export const isLivestock = createSafeCachedSelector(
  [orgSelect.currentOrgOrganizationTypes],
  R.includes(3),
)(orgId);
