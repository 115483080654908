import * as R from 'ramda';
import {
  ADMIN_REQUESTS_LIST,
  ADMIN_REQUESTS_LIST_SUCCESS,
  ADMIN_REQUESTS_LIST_FAILED,
  ACTIVITY_REQUESTS_LIST,
  ACTIVITY_REQUESTS_LIST_SUCCESS,
  ACTIVITY_REQUESTS_LIST_FAILED,
  LOCATION_REQUESTS_LIST,
  LOCATION_REQUESTS_LIST_SUCCESS,
  LOCATION_REQUESTS_LIST_FAILED,
  ADMIN_REQUEST_CREATE_SUCCESS,
  ADMIN_REQUEST_CLEAR_SUCCESS,
  FETCH_USER_REQUESTS,
  FETCH_USER_REQUESTS_SUCCESS,
  FETCH_REQUEST_OPTIONS,
  FETCH_REQUEST_OPTIONS_SUCCESS,
  UPDATE_REQUEST_SUCCESS,
  CLEAR_FILTERS,
} from 'sow/actions/adminRequest';
import * as adminRequestActions from 'sow/actions/adminRequest';

const initialState = {
  orgId: null,
  planAppId: null,
  reportFilters: {},
  worksheetRequests: {
    list: [],
    isFetching: false,
    isLoaded: false,
  },
  activityRequests: {
    list: [],
    isFetching: false,
    isLoaded: false,
  },
  locationRequests: {
    list: [],
    isFetching: false,
    isLoaded: false,
  },
};

let requests = {
  list: [],
  isFetching: true,
  isLoaded: false,
};

export default function adminRequestsList(state = initialState, action) {
  switch (action.type) {
    case ADMIN_REQUESTS_LIST:
      requests = {
        list: [],
        isFetching: true,
        isLoaded: false,
      };
      return {
        ...initialState,
        worksheetRequests: requests,
        orgId: action.data.orgId,
        planAppId: action.data.planAppId,
      };

    case ADMIN_REQUESTS_LIST_SUCCESS:
      requests = {
        list: [...action.data],
        isFetching: false,
        isLoaded: true,
      };
      return { ...state, worksheetRequests: requests };

    case ADMIN_REQUESTS_LIST_FAILED:
      requests = {
        list: [],
        isFetching: false,
        isLoaded: false,
      };
      return { ...state, worksheetRequests: requests };

    case ACTIVITY_REQUESTS_LIST:
      requests = {
        list: [],
        isFetching: true,
        isLoaded: false,
      };
      return {
        ...initialState,
        activityRequests: requests,
        orgId: action.data.orgId,
        planAppId: action.data.planAppId,
      };

    case ACTIVITY_REQUESTS_LIST_SUCCESS:
      requests = {
        list: [...action.data],
        isFetching: false,
        isLoaded: true,
      };
      return { ...state, activityRequests: requests };

    case ACTIVITY_REQUESTS_LIST_FAILED:
      requests = {
        list: [],
        isFetching: false,
        isLoaded: false,
      };
      return { ...state, activityRequests: requests };

    case LOCATION_REQUESTS_LIST:
      requests = {
        list: [],
        isFetching: true,
        isLoaded: false,
      };
      return {
        ...initialState,
        locationRequests: requests,
        orgId: action.data.orgId,
        planAppId: action.data.planAppId,
      };

    case LOCATION_REQUESTS_LIST_SUCCESS:
      requests = {
        list: [...action.data],
        isFetching: false,
        isLoaded: true,
      };
      return { ...state, locationRequests: requests };

    case LOCATION_REQUESTS_LIST_FAILED:
      requests = {
        list: [],
        isFetching: false,
        isLoaded: false,
      };
      return { ...state, locationRequests: requests };

    case ADMIN_REQUEST_CREATE_SUCCESS:
      requests = {
        list: [...state.worksheetRequests.list, action.data],
        isFetching: false,
        isLoaded: true,
      };
      if (!R.isNil(action.data.location_id)) {
        requests = {
          list: [...state.locationRequests.list, action.data],
          isFetching: false,
          isLoaded: true,
        };
        return { ...state, locationRequests: requests };
      } else {
        return { ...state, worksheetRequests: requests };
      }

    case ADMIN_REQUEST_CLEAR_SUCCESS:
      const id = Array.isArray(action.data) ? action.data[0] : action.data;
      const newList = removeItem([...state.worksheetRequests.list], id);
      const newLocationList = removeItem([...state.locationRequests.list], id);
      requests = {
        list: newList,
        isFetching: false,
        isLoaded: true,
      };
      let locationRequests = {
        list: newLocationList,
        isFetching: false,
        isLoaded: true,
      };
      return { ...state, worksheetRequests: requests, locationRequests };

    case FETCH_USER_REQUESTS:
      return { ...state, reportFilters: action.params, todoList: { isFetching: true } };

    case FETCH_USER_REQUESTS_SUCCESS:
      return { ...state, todoList: { isFetching: false, list: action.data } };

    case FETCH_REQUEST_OPTIONS:
      return { ...state, todoListFilters: { isFetching: true } };

    case FETCH_REQUEST_OPTIONS_SUCCESS:
      return {
        ...state,
        todoListFilters: { isFetching: false, filterOptions: action.data },
      };

    case UPDATE_REQUEST_SUCCESS:
      return { ...state, todoList: { isFetching: false, list: action.data } };

    case CLEAR_FILTERS:
      return { ...state, todoList: {}, todoListFilters: {}, reportFilters: {} };

    default:
      return state;
  }
}

function removeItem(array, id) {
  return array.filter(item => item.id !== id);
}
