import React from 'react';
import { Formik, Form, Field } from 'formik';

import { systemAdminRoute, priorityDesignationTypeEditRoute } from 'sow/routes';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FormikField from 'sow/components/organisms/FormikField';
import Button from 'sow/components/atoms/Button';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Spinner from 'sow/components/atoms/Spinner';
import DragDropListContainer from 'sow/containers/DragDropListContainer';

const PriorityDesignationTypeList = ({
  loadingList,
  initialValues,
  priorityDesignationTypesList,
  onSearch,
  onReset,
  updateSortOrder,
  listFiltered,
}) => (
  <Widget className="system-admin-list-edit">
    <WidgetHeading>Priority Designation Types</WidgetHeading>
    <WidgetBody>
      <Formik
        initialValues={initialValues}
        onSubmit={onSearch}
        onReset={onReset}
        enableReinitialize
      >
        {({ handleReset, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Row className="form-row">
              <Column className="form-column" xs={12} md={8}>
                <Field
                  className="input-filter"
                  label="Name"
                  placeholder=""
                  name={'name'}
                  component={FormikField}
                />
              </Column>
              <Column className="form-column" xs={12} sm={6} md={4}>
                <Row className="button-row search-button-row">
                  <Column className="button-column" xs={6} sm={6} md={6}>
                    <Button className="form-button search-button" type="submit">
                      Search
                    </Button>
                  </Column>
                  <Column className="button-column" xs={6} sm={6} md={6}>
                    <Button
                      className="form-button reset-return-button"
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                  </Column>
                </Row>
              </Column>
            </Row>
          </Form>
        )}
      </Formik>
      <HorizontalRule className="divider" />
      <Row className="form-row">
        <Column className="form-column" xs={12} sm={6} md={4}>
          <Row className="button-row">
            <Column className="button-column" xs={6} sm={6} md={6}>
              <Button
                className="form-button"
                to={priorityDesignationTypeEditRoute('new')}
                primary
              >
                <FontAwesome className="button-icon" icon="plus" />
                Add
              </Button>
            </Column>
            <Column className="button-column" xs={6} sm={6} md={6}>
              <Button className="form-button reset-return-button" to={systemAdminRoute()}>
                Back
              </Button>
            </Column>
          </Row>
        </Column>
      </Row>
      {loadingList ? (
        <Spinner />
      ) : (
        <Row className="form-row list-row">
          <Column className="form-column" xs={12}></Column>
          <DragDropListContainer
            list={priorityDesignationTypesList}
            editRoute={priorityDesignationTypeEditRoute}
            updateSortOrder={updateSortOrder}
            listFiltered={listFiltered}
          ></DragDropListContainer>
        </Row>
      )}
    </WidgetBody>
  </Widget>
);

export default PriorityDesignationTypeList;
